import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useMemo, useState } from "react";
import { FixNumber } from "../../../../common/orders";
import { getCurrencyShorthand } from "../../../../providers/StationProvider";
import SimpleTable from "../../../common/tables/SimpleTable";
import { ToggleSurface } from "../../../common/core/Surface";
import { Button, Grid, Typography } from "@mui/material";
import ConfirmDialog from "../../../common/inputs/ConfirmDialog";
import { OrderEnumsLists } from "../../../../common/constants";
import { useCashExport } from "../../../../hooks/useCashExport";
import MissingExportSettingsWarning from "../../../common/billing/MissingExportSettingsWarning";
import FlagChip from "../../../common/core/FlagChip";


export default function CashOrdersReadyForJournalingSection() {
  const { t } = useTranslation();
  const { tabId } = useParams();
  const navigate = useNavigate();
  const { orders, loading, working, exportAction, exportAll, hasExportSettings } = useCashExport();
  const [selectionModel, setSelectionModel] = useState([]);
  const showLoading = loading && orders.length === 0 || working;

  const columns = useMemo(() => {
    return [
      {
        field: "number",
        type: "number",
        headerName: t("entity:order:number"),
        flex: 0
      },
      {
        field: "missionNumber",
        type: "number",
        headerName: t("common:tables:mission"),
        flex: 0
      },
      {
        field: "customer.name",
        headerName: t("entity:order:customer"),
        valueGetter: (params) => params.row.customer?.name || "-",
        minWidth: 160,
        flex: 0.5,
      },
      {
        field: "mission.target.carDetails.registration||mission.target.boatDetails.registration",
        headerName: t("entity:mission:target:registration"),
        valueGetter: params => {
          const type = params.row.mission.target.type;
          const target = type === 1 ? params.row.mission.target?.carDetails : (type === 2 ? params.row.mission.target.boatDetails : null);
          return target?.registration || "-";
        },
        minWidth: 100,
        flex: 0.5
      },
      {
        field: "mission.actors.requester.name||mission.actors.owner.name",
        headerName: t("common:tables:requester"),
        valueGetter: (params) => params.row.mission.actors.ownerIsNotRequester ? params.row.mission.actors.requester.name : params.row.mission.actors.owner.name,
        flex: 1,
        minWidth: 120,
      },
      {
        field: "payment.method",
        headerName: t("entity:order:payment:method"),
        valueGetter: (params) => t(`order:enums:paymentMethod:${OrderEnumsLists.PaymentMethod[params.row.payment.method]}`),
        minWidth: 160
      },
      {
        field: "payment.calculatedTotal.value",
        type: "number",
        entityType: "decimal",
        headerName: t("entity:order:payment:calculatedTotal"),
        valueGetter: (params) => Number(params.row.payment.calculatedTotal?.value || "0"),
        valueFormatter: (params) => FixNumber(params.value),
        renderCell: (params) => `${FixNumber(params.row.payment.calculatedTotal?.value)} ${getCurrencyShorthand(t, params.row.payment.calculatedTotal?.currency)}`,
        minWidth: 120
      },
      {
        field: "deductible.price.value",
        type: "number",
        entityType: "decimal",
        headerName: t("entity:order:deductible"),
        valueGetter: (params) => Number(params.row.deductible?.price?.value || "0"),
        valueFormatter: (params) => FixNumber(params.value),
        renderCell: (params) => params.row.deductible ? `${FixNumber(params.row.deductible?.price?.value)} ${getCurrencyShorthand(t, params.row.deductible?.price?.currency)}` : "-",
        minWidth: 120
      },
      {
        field: "payment.sender.name",
        headerName: t("entity:order:sender"),
        valueGetter: (params) => params.row.payment.sender?.name || "-",
        minWidth: 160,
        flex: 0.5
      },
      {
        field: "mission.actors.contract.agreement.name",
        headerName: t("entity:agreement:type"),
        valueGetter: (params) => params.row.mission?.actors?.contract?.agreement?.name || "-",
        minWidth: 160,
        flex: 0.5
      },
      {
        field: "comment",
        headerName: t("entity:order:internalComment"),
        valueGetter: (params) => params.row.comment || "-",
        minWidth: 160,
        flex: 0.5
      },
      {
        field: "settled",
        type: "singleSelect",
        headerName: t("entity:order:cashSettlement"),
        minWidth: 120,
        flex: 0.75,
        valueGetter: (params) => !params.row.payment.terminal ? 0 : (params.row.payment.terminal.settled ? 2 : 1),
        renderCell: (params) => <FlagChip value={params.value !== 1} label={params.value === 0 ? t("common:irrelevant") : params.value === 1 ? t("common:unsettled") : t("common:settled")} />,
        valueOptions: [
          { label: t("common:irrelevant"), value: 0 },
          { label: t("common:unsettled"), value: 1 },
          { label: t("common:settled"), value: 2 },
        ]
      },
    ]
  }, [t]);

  const onRowClick = useCallback((params, ev) => {
    navigate(!tabId ? `./ready/${params.row.missionId}/${params.id}` : `./${params.row.missionId}/${params.id}`, { relative: "path" });
  }, [navigate, tabId]);

  const onExportAll = useCallback(async () => {
    const { success, exportId } = await exportAll(selectionModel);
    if (!!success) {
      navigate(!tabId ? `./journals/${exportId}` : `../journals/${exportId}`, { relative: "path" });
    }
  }, [exportAll, navigate, tabId, selectionModel]);

  return (
    <>
      {
        orders.length !== 0 ? (
          <ToggleSurface surface xl={4} lg={5} md={6} sm={8} xs={12}>
            <Grid item container xs={12} spacing={1}>

              <Grid item xs={12}>
                <Typography variant="subtitle1">{t("cashOrdersReadyForExport:exportAllTitle")}</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body2">
                  {t("cashOrdersReadyForExport:exportAllDescriptionPart1")} {selectionModel.length} {t("cashOrdersReadyForExport:exportAllDescriptionPart2")}
                </Typography>
              </Grid>

              {
                !hasExportSettings ? (
                  <MissingExportSettingsWarning />
                ) : null
              }

              <Grid item xs={12} container justifyContent="flex-end">
                <Button variant="contained" disabled={showLoading || loading || !hasExportSettings || selectionModel.length === 0} onClick={onExportAll}>{t("cashOrdersReadyForExport:exportAllButton")}</Button>
              </Grid>
            </Grid>
          </ToggleSurface>
        ) : null
      }

      <ToggleSurface>
        <Grid item xs={12}>
          <SimpleTable
            tableId="cashorders_readyforexport"
            onRowClick={onRowClick}
            columns={columns}
            rows={orders}
            loading={showLoading}
            emptyStateTitle={t("cashOrdersReadyForExport:emptyStateTitle")}
            emptyStateDescription={t("cashOrdersReadyForExport:emptyStateDescription")}
            checkboxSelection
            disablePaging
            onSelectionModelChange={setSelectionModel}
            selectionModel={selectionModel}
            xs={12}
          />
        </Grid>
      </ToggleSurface>

      <ConfirmDialog
        task={exportAction}
        title={t("cashOrdersReadyForExport:exportAllDialog:title")}
        description={t("cashOrdersReadyForExport:exportAllDialog:description")}
        confirmText={t("cashOrdersReadyForExport:exportAllDialog:confirmText")}
      />

    </>
  );
}

