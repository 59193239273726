import { makeStyles } from "tss-react/mui";
import { Grid, Autocomplete, TextField, IconButton, Typography } from "@mui/material";
import { useCallback, useEffect, useState, useMemo } from "react";
import { PinDropOutlined as PinDropIcon, PinDropRounded as PinDropSelectedIcon, LocationOn as LocationOnIcon, DragHandle as DragHandleIcon, Person as PersonIcon, Warehouse as WarehouseIcon } from "@mui/icons-material";
import { useStation } from "../../../providers/StationProvider";
import mapboxgl from "mapbox-gl";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useSearch, Location } from "@emberly/zenith-client";
import { FormatLocation, MapboxPickerToLocation, MapboxQueryToLocation } from "../../../common/maphelpers";
import { getTextSearch } from "../../../common/search";
import DesktopLocationPicker from "./pickers/DesktopLocationPicker";
import { useDevice } from "../../../providers/DeviceProvider";
import MobileLocationPicker from "./pickers/MobileLocationPicker";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      background: theme.palette.common.grey.input,
      position: "relative",
      borderRadius: "8px",
      "& .MuiInputBase-root": {
        backgroundColor: "transparent !important"
      },
      "&:hover": {
        "&:after": {
          zIndex: 3,
          position: "absolute",
          height: "100%",
          width: "100%",
          right: 0,
          background: theme.palette.action.hover,
          borderRadius: "8px",
          content: "''",
          display: "block",
          pointerEvents: "none"
        },
        "& .MuiInputBase-root": {
          backgroundColor: "transparent !important"
        }
      }
    },
    pickerButton: {
      marginRight: theme.spacing(1)
    },
  })
);

const FORWARD_PASS = (x) => x;
const IS_OPTION_EQUAL = (a, b) => a.mapboxId === b.mapboxId;

export default function LocationPicker(props) {
  const { picker, draggable, label, value, onChange, dragHandleProps, disabled, enableContactSearching, enableWarehouseSearching, freeSolo } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { isMobile } = useDevice();
  const { warehouses } = useStation();
  // use station coordinates, from context, for search bias, and map init

  const [option, setOption] = useState(value || null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const { station } = useStation();

  // Dialog
  const [pickerOpen, setPickerOpen] = useState(false);
  const [pickerLoading, setPickerLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [popupOpen, setPopupOpen] = useState(false);

  const filterQuery = useMemo(() => {
    return getTextSearch("name", searchQuery);
  }, [searchQuery]);

  const onPopupClose = useCallback(() => setPopupOpen(false), []);

  const onPopupOpen = useCallback(() => {
    setSearchQuery(inputValue);
    setPopupOpen(true);
  }, [inputValue]);


  useEffect(() => {
    setOption(value);
  }, [value, station]);


  const { entities: contacts } = useSearch("Contact", filterQuery, popupOpen && enableContactSearching);

  useEffect(() => {
    let alive = true;
    const timer = setTimeout(() => {

      if (popupOpen) {
        setSearchQuery(inputValue);
      }

      if (!!inputValue && option?.title !== inputValue && popupOpen) {

        const proximityBias = station?.location?.coordinates?.length === 2 ? `&proximity=${station.location.coordinates.join(",")}` : "";

        // TODO session token and proximity and language, used to be "en"
        axios(`https://api.mapbox.com/search/searchbox/v1/suggest?q=${inputValue}&language=no&country=no%2Cse%2Cdk&session_token=${"test"}&access_token=${mapboxgl.accessToken}&types=poi,address,street${proximityBias}`)
          .then((res) => {
            const { suggestions } = res.data;
            // todo might need to do something with attribution here, from res.dat
            if (!alive) return;

            
            setOptions(suggestions.filter(t => t.feature_type === "poi" || t.feature_type === "address" || t.feature_type === "street").map((t, key) => ({
              title: t.name,
              address: t.full_address,
              formattedPlace: t.place_formatted,
              mapboxId: t.mapbox_id,
              type: t.feature_type,
              isContact: false,
              key
            })));

          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    }, 300);

    return () => {
      clearTimeout(timer);
      alive = false;
    };
  }, [inputValue, option, enableContactSearching, station, popupOpen]);

  const renderedOptions = useMemo(() => {
    let result = [];
    let q = searchQuery.toLocaleLowerCase();

    if (enableWarehouseSearching) {
      const opts = warehouses
        .filter(t => !!t.location && t.location?.coordinates?.length >= 2 && t?.name?.toLocaleLowerCase()?.includes(q))
        .map((t, key) => ({
          id: t.location.id,
          mapboxId: t.location.mapboxId,
          coordinates: t.location.coordinates,
          place: t.location.place,
          address: t.location.address,
          region: t.location.region,
          postCode: t.location.postCode,
          country: t.location.country,
          title: t.name || t.location.title,
          type: t.location.type,
          isWarehouse: true,
          key: `w_${key}`
        }));

      const set = new Set(result.map(t => t.mapboxId));

      result = result.concat(opts.filter(t => !set.has(t.mapboxId)));
    }

    if (enableContactSearching) {
      const opts = contacts
        .filter(t => !!t.location && t.location?.coordinates?.length >= 2)
        .map((t, key) => ({
          id: t.location.id,
          mapboxId: t.location.mapboxId,
          coordinates: t.location.coordinates,
          place: t.location.place,
          address: t.location.address,
          region: t.location.region,
          postCode: t.location.postCode,
          country: t.location.country,
          title: t.name || t.location.title,
          type: t.location.type,
          isContact: true,
          key: `c_${key}`
        }));

      const set = new Set(result.map(t => t.mapboxId));

      result = result.concat(opts.filter(t => !set.has(t.mapboxId)));
    }

    return result.length === 0 ? options : result.concat(options);
  }, [options, contacts, enableContactSearching, warehouses, enableWarehouseSearching, searchQuery]);

  const onOptionSelected = useCallback((ev, option) => {
    if (!!option) {
      setOption(option);
      // TODO session token

      if (option.isContact || option.isWarehouse) {
        onChange(new Location({ ...option }));
      } else {
        axios(`https://api.mapbox.com/search/searchbox/v1/retrieve/${option.mapboxId}?session_token=${"test"}&access_token=${mapboxgl.accessToken}`)
          .then(res => {
            const feature = res.data?.features[0];
            onChange(MapboxQueryToLocation(option, feature));
          })
          .catch(err => {
            console.log(err, err);
          });
      }

    } else {
      if (ev?.type !== "change") {
        onChange(new Location({ address: "" }));
      }
    }
  }, [onChange]);

  const onPickerClose = useCallback(() => {
    setPickerOpen(false);
  }, []);

  const onPickerConfirm = useCallback((pickerCoordinates) => {
    if (!!pickerCoordinates) {
      // try to geocode, show loading the meantime
      const timer = setTimeout(() => setPickerLoading(true), 250);

      axios(`https://api.mapbox.com/geocoding/v5/mapbox.places/${pickerCoordinates[0]},${pickerCoordinates[1]}.json?session_token=${"test"}&access_token=${mapboxgl.accessToken}`)
        .then(res => {
          clearTimeout(timer);
          const { features } = res.data;
          if (features.length > 0) {
            const feature = features[0];
            onChange(MapboxPickerToLocation(pickerCoordinates, feature));
          } else {
            onChange(new Location({
              coordinates: pickerCoordinates,
              title: pickerCoordinates.join(", "),
              address: pickerCoordinates.join(", "),
            }));
          }

          setPickerLoading(false);
          setPickerOpen(false);
        })
        .catch(err => {
          clearTimeout(timer);
          setPickerLoading(false);
          console.log("err", err);
        })
    }
  }, [onChange]);

  const onInputChange = useCallback((event, newInputValue) => {
    setInputValue(newInputValue);

    if (freeSolo && event?.type === "change") {

      onChange(new Location({
        address: newInputValue,
      }));

    }
  }, [onChange, freeSolo]);

  return (
    <Grid container item xs direction="row" className={classes.root} justifyContent="flex-start" alignItems="center">

      <Grid item xs>
        <Autocomplete
          onReset={console.log}
          getOptionLabel={FormatLocation}
          isOptionEqualToValue={IS_OPTION_EQUAL}
          options={renderedOptions}
          onOpen={onPopupOpen}
          onClose={onPopupClose}
          autoComplete
          fullWidth
          freeSolo
          clearOnBlur
          filterOptions={FORWARD_PASS}
          includeInputInList
          value={option}
          disabled={disabled}
          noOptionsText={t("common:noResults")}
          onChange={onOptionSelected}
          onInputChange={onInputChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              size="small"
              variant="filled"
              fullWidth
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
              }}
            />
          )}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.key}>
                <Grid container alignItems="center">
                  <Grid item sx={{ display: "flex", width: 44 }}>
                    {option.isContact ? (
                      <PersonIcon sx={{ color: "text.secondary" }} />
                    ) : option.isWarehouse ? (
                      <WarehouseIcon sx={{ color: "text.secondary" }} />
                    ) : (
                      <LocationOnIcon sx={{ color: "text.secondary" }} />
                    )}
                  </Grid>
                  <Grid item sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}>
                    <Typography>
                      {option.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {option.address || option.formattedPlace}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            );
          }}
        />
      </Grid>

      {
        !!draggable ? (
          <Grid item>
            <IconButton size="small" {...dragHandleProps} disabled={disabled}>
              <DragHandleIcon />
            </IconButton>
          </Grid>
        ) : null
      }

      {
        !!picker ? (
          <Grid item>
            <IconButton size="small" className={classes.pickerButton} onClick={() => setPickerOpen(true)} disabled={disabled}>
              {!!value?.coordinates?.length ? <PinDropSelectedIcon /> : <PinDropIcon />}
            </IconButton>
            {
              pickerOpen ? (
                isMobile ? (
                  <MobileLocationPicker value={value} onCancel={onPickerClose} loading={pickerLoading} onConfirm={onPickerConfirm} />
                ) : (
                  <DesktopLocationPicker value={value} onCancel={onPickerClose} loading={pickerLoading} onConfirm={onPickerConfirm} />
                )
              ) : null
            }
          </Grid>
        ) : null
      }

    </Grid>
  );
}


