import { Autocomplete, TextField, Box } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createFilterOptions } from "@mui/material/Autocomplete";

const filterOptions = createFilterOptions({
  stringify: (option) => `${option.label} (${option.code})`,
});

export default function CountrySelect(props) {
  const { disabled, label, value, onChange } = props;
  const { t } = useTranslation();

  const { countries, data } = useMemo(() => {
    const data = t("country", { returnObjects: true });
    return {
      countries: Object.keys(data).map(code => ({
        code,
        label: data[code]
      })),
      data
    }
  }, [t]);

  const option = useMemo(() => !!value ? (!!data[value] ? { label: data[value], code: value } : { label: "Norge", code: "NO" }) : null, [value, data]);

  const onOptionSelected = useCallback((ev, option) => {
    if (!!option) {
      onChange(option.code);
    } else {
      onChange("");
    }
  }, [onChange]);

  return (
    <Autocomplete
      disabled={disabled}
      id="country-select"
      options={countries}
      autoHighlight
      isOptionEqualToValue={(o) => o.code === option.code}
      value={option}
      onChange={onOptionSelected}
      filterOptions={filterOptions}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <Box
            key={key}
            component="li"
            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
            {...optionProps}
          >
            <img
              loading="lazy"
              width="20"
              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
              alt=""
            />
            {option.label} ({option.code})
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          variant="filled"
          label={label}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}
