import { Grid, Button, DialogActions, DialogContent, DialogTitle, TextField, FormControlLabel, Checkbox, Divider, FormControl, InputLabel, Select, MenuItem, FormHelperText, ListItemText, ListSubheader, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import { useEntityField, Defaults, useEntities } from "@emberly/zenith-client";
import { useMission } from "../../../../../../providers/MissionProvider";
import { CollapseGridWithoutFocusItem } from "../../../../../common/core/CollapseGridItem";
import TextFieldDecimal from "../../../../../common/inputs/TextFieldDecimal";
import { useStation } from "../../../../../../providers/StationProvider";
import { makeStyles } from "tss-react/mui";
import ResponsiveDialog from "../../../../../common/core/ResponsiveDialog";

const pickLastValid = (a, b) => typeof b === "number" ? b : a;


const useStyles = makeStyles()(
  (theme) => ({
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    startLocationSelect: {
      "& .MuiSelect-select": {
        paddingTop: theme.spacing(2)
      }
    }
  })
);


export default function OverrideRouteDetailsDialog(props) {
  const { open, onClose, path } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();


  const { entities: startLocations } = useEntities("StartLocation");
  const { value: routeData } = useEntityField(`${path}.route`, Defaults.Object);
  const { value: vehicle } = useEntityField(`${path}.execution.vehicle`, Defaults.Object);
  const { updateMissionField } = useMission();
  const { station } = useStation();
  
  const [overriddenStartLocation, setOverriddenStartLocation] = useState(routeData.overriddenStartLocation || null);
  const hasOverriddenStartLocation = !!overriddenStartLocation;

  const [overriddenDistanceInRoute, setOverriddenDistanceInRoute] = useState(typeof routeData.overriddenDistanceInRouteKM === "number" ? pickLastValid(routeData.distanceInRouteKM, routeData.overriddenDistanceInRouteKM) : null);
  const hasOverriddenDistanceInRoute = typeof overriddenDistanceInRoute === "number";

  const [overriddenDistanceToRoute, setOverriddenDistanceToRoute] = useState(typeof routeData.overriddenDistanceToRouteKM === "number" ? pickLastValid(routeData.distanceToRouteKM, routeData.overriddenDistanceToRouteKM) : null);
  const hasOverriddenDistanceToRoute = typeof overriddenDistanceToRoute === "number";


  const [overriddenWeight, setOverriddenWeight] = useState(typeof routeData.overriddenWeight === "number" ? pickLastValid(vehicle.weight, routeData.overriddenWeight) : null);
  const hasOverriddenWeight = typeof overriddenWeight === "number";

  const [overriddenWidth, setOverriddenWidth] = useState(typeof routeData.overriddenWidth === "number" ? pickLastValid(vehicle.width, routeData.overriddenWidth) : null);
  const hasOverriddenWidth = typeof overriddenWidth === "number";

  const [overriddenHeight, setOverriddenHeight] = useState(typeof routeData.overriddenHeight === "number" ? pickLastValid(vehicle.height, routeData.overriddenHeight) : null);
  const hasOverriddenHeight = typeof overriddenHeight === "number";

  const [overrideReason, setOverrideReason] = useState(routeData.overrideReason || "");

  const onConfirm = useCallback(() => {
    updateMissionField(`${path}.route.overriddenStartLocation`, !!overriddenStartLocation ? { id: overriddenStartLocation.id, name: overriddenStartLocation.name, location: overriddenStartLocation.location } : null);
    updateMissionField(`${path}.route.overriddenDistanceToRouteKM`, overriddenDistanceToRoute);
    updateMissionField(`${path}.route.overriddenDistanceInRouteKM`, overriddenDistanceInRoute);
    updateMissionField(`${path}.route.overriddenWeight`, overriddenWeight);
    updateMissionField(`${path}.route.overriddenWidth`, overriddenWidth);
    updateMissionField(`${path}.route.overriddenHeight`, overriddenHeight);
    updateMissionField(`${path}.route.overrideReason`, overrideReason);
    onClose();
  }, [onClose, path, overrideReason, overriddenStartLocation, overriddenDistanceToRoute, overriddenDistanceInRoute, overriddenWeight, overriddenWidth, overriddenHeight, updateMissionField]);

  return (
    <ResponsiveDialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {t("mission:cards:tasks:map:overrideDialog:title")}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>


          <Grid item xs={12}>
            <FormControl fullWidth size="small" variant="filled" className={classes.startLocationSelect}>
              <InputLabel>{t("mission:cards:tasks:map:overrideDialog:overriddenStartLocation")}</InputLabel>
              <Select
                size="small"
                value={overriddenStartLocation?.id || "station"}
                onChange={(ev) => setOverriddenStartLocation(startLocations.find(t => t.id === ev.target.value))}
              >
                <ListSubheader>{t("mission:cards:tasks:map:overrideDialog:station")}</ListSubheader>
                <MenuItem value="station">
                  <ListItemText
                    primary={station?.displayName}
                    secondary={station?.location?.address || ""}
                  />
                </MenuItem>
                {
                  !!startLocations.length ? (
                    <Divider />
                  ) : null
                }
                {
                  !!startLocations.length ? (
                    <ListSubheader>{t("mission:cards:tasks:map:overrideDialog:startLocation")}</ListSubheader>
                  ) : null
                }
                {
                  !!overriddenStartLocation && !startLocations.find(sl => sl.id === overriddenStartLocation.id) ? (
                    <MenuItem value={overriddenStartLocation.id}>
                      <ListItemText
                        primary={overriddenStartLocation.name}
                        secondary={overriddenStartLocation.description || "-"}
                      />
                    </MenuItem>
                  ) : null
                }
                {
                  startLocations.map((sl, key) => (
                    <MenuItem value={sl.id} key={key}>
                      <ListItemText
                        primary={sl.name}
                        secondary={sl.description || "-"}
                      />
                    </MenuItem>
                  ))
                }
              </Select>
              <FormHelperText>{t("mission:cards:tasks:map:overrideDialog:overriddenStartLocationDescription")}</FormHelperText>
            </FormControl>
          </Grid>


          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle2">{t("mission:cards:tasks:map:overrideDialog:overrideDimensionsTitle")}</Typography>
          </Grid>


          <Grid item xs={12}>
            <FormControlLabel
              label={t("mission:cards:tasks:map:overrideDialog:overriddenWeight")}
              control={<Checkbox size="small" checked={hasOverriddenWeight} onChange={(ev, checked) => setOverriddenWeight(checked ? (vehicle?.weight || 0) : null)} />}
            />
          </Grid>

          <CollapseGridWithoutFocusItem in={hasOverriddenWeight} xs={12}>
            <TextFieldDecimal
              label={t("mission:cards:tasks:map:overrideDialog:overriddenWeightLabel")}
              variant="filled"
              value={hasOverriddenWeight ? overriddenWeight : null}
              onChange={(ev, v) => setOverriddenWeight(v)}
              suffix=" kg"
              selectOnFocus
              fullWidth
              nullable
              size="small"
            />
          </CollapseGridWithoutFocusItem>


          <Grid item xs={12}>
            <FormControlLabel
              label={t("mission:cards:tasks:map:overrideDialog:overriddenHeight")}
              control={<Checkbox size="small" checked={hasOverriddenHeight} onChange={(ev, checked) => setOverriddenHeight(checked ? (vehicle?.height || 0) : null)} />}
            />
          </Grid>

          <CollapseGridWithoutFocusItem in={hasOverriddenHeight} xs={12}>
            <TextFieldDecimal
              label={t("mission:cards:tasks:map:overrideDialog:overriddenHeightLabel")}
              variant="filled"
              value={hasOverriddenHeight ? overriddenHeight : null}
              onChange={(ev, v) => setOverriddenHeight(v)}
              suffix=" m"
              selectOnFocus
              nullable
              fullWidth
              size="small"
            />
          </CollapseGridWithoutFocusItem>

          <Grid item xs={12}>
            <FormControlLabel
              label={t("mission:cards:tasks:map:overrideDialog:overriddenWidth")}
              control={<Checkbox size="small" checked={hasOverriddenWidth} onChange={(ev, checked) => setOverriddenWidth(checked ? (vehicle?.width || 0) : null)} />}
            />
          </Grid>

          <CollapseGridWithoutFocusItem in={hasOverriddenWidth} xs={12}>
            <TextFieldDecimal
              label={t("mission:cards:tasks:map:overrideDialog:overriddenWidthLabel")}
              variant="filled"
              value={hasOverriddenWidth ? overriddenWidth : null}
              onChange={(ev, v) => setOverriddenWidth(v)}
              suffix=" m"
              selectOnFocus
              nullable
              fullWidth
              size="small"
            />
          </CollapseGridWithoutFocusItem>


          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle2">{t("mission:cards:tasks:map:overrideDialog:overrideDistanceTitle")}</Typography>
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              label={t("mission:cards:tasks:map:overrideDialog:overriddenDistanceInRoute")}
              control={<Checkbox size="small" checked={hasOverriddenDistanceInRoute} onChange={(ev, checked) => setOverriddenDistanceInRoute(checked ? routeData.distanceInRouteKM : null)} />}
            />
          </Grid>

          <CollapseGridWithoutFocusItem in={hasOverriddenDistanceInRoute} xs={12}>
            <TextFieldDecimal
              label={t("mission:cards:tasks:map:overrideDialog:overriddenDistanceInRouteLabel")}
              variant="filled"
              value={hasOverriddenDistanceInRoute ? overriddenDistanceInRoute : null}
              onChange={(ev, v) => setOverriddenDistanceInRoute(v)}
              selectOnFocus
              nullable
              fullWidth
              suffix=" km"
              size="small"
            />
          </CollapseGridWithoutFocusItem>

          <Grid item xs={12}>
            <FormControlLabel
              label={t("mission:cards:tasks:map:overrideDialog:overriddenDistanceToRoute")}
              control={<Checkbox size="small" checked={hasOverriddenDistanceToRoute} onChange={(ev, checked) => setOverriddenDistanceToRoute(checked ? routeData.distanceToRouteKM : null)} />}
            />
          </Grid>

          <CollapseGridWithoutFocusItem in={hasOverriddenDistanceToRoute} xs={12}>
            <TextFieldDecimal
              label={t("mission:cards:tasks:map:overrideDialog:overriddenDistanceToRouteLabel")}
              variant="filled"
              value={hasOverriddenDistanceToRoute ? overriddenDistanceToRoute : null}
              onChange={(ev, v) => setOverriddenDistanceToRoute(v)}
              selectOnFocus
              fullWidth
              nullable
              suffix=" km"
              size="small"
            />
          </CollapseGridWithoutFocusItem>


          <CollapseGridWithoutFocusItem in={hasOverriddenStartLocation || hasOverriddenDistanceInRoute || hasOverriddenDistanceToRoute || hasOverriddenHeight || hasOverriddenWidth || hasOverriddenWeight || !!overrideReason} xs={12}>
            <Divider style={{ marginBottom: "24px" }} className={classes.divider} />

            <TextField
              label={t("mission:cards:tasks:map:overrideDialog:overrideReasonLabel")}
              variant="filled"
              value={overrideReason}
              onChange={ev => setOverrideReason(ev.target.value)}
              fullWidth
              multiline
              size="small"
            />
          </CollapseGridWithoutFocusItem>


        </Grid>


      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t("common:cancel")}
        </Button>
        <Button variant="contained" color="primary" onClick={onConfirm}>
          {t("common:change")}
        </Button>
      </DialogActions>
    </ResponsiveDialog>
  );
}
