import { Grid, Autocomplete, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Person as PersonIcon } from "@mui/icons-material";
import { useEntityById } from "@emberly/zenith-client";
import { useTranslation } from "react-i18next";

export default function ContactPersonPicker(props) { 
  const { label, value, onChange, disabled, contactId, load } = props;
  const { entity } = useEntityById("Contact", contactId, !!contactId && load);
  const [option, setOption] = useState(value || null);
  const [inputValue, setInputValue] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    setOption(value);
  }, [value]);

  // Make input, with button, and allow for endAdornment prop to be used as drag handle etc

  const onOptionSelected = useCallback((ev, option) => {
    if (!!option) {
      setOption(option);
      onChange(inputValue, option);
    } else {
      onChange(inputValue, null);
    }
  }, [onChange, inputValue]);


  return (
    <Autocomplete
      getOptionLabel={(option) => typeof option === 'string' ? option : option.name}
      isOptionEqualToValue={(a, b) => a.id === b.id}
      options={entity?.contactPersons || []}
      autoComplete
      fullWidth
      freeSolo
      filterOptions={(x) => x}
      includeInputInList
      value={option}
      disabled={disabled}
      noOptionsText={t("common:noResults")}
      onChange={onOptionSelected}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        onChange(newInputValue, null);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          size="small"
          variant="filled"
          fullWidth
          sx={{
            "&:hover": {
              backgroundColor: "transparent"
            }
          }}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
          }}
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <PersonIcon sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                <Typography>
                  {option.name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {option?.phoneNumber}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}


