import { makeStyles } from "tss-react/mui";
import { Grid, Typography, Divider, Table, TableBody, TableRow, TableCell } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEntity } from "@emberly/zenith-client";
import { useStation } from "../../../providers/StationProvider";
import { MissionEnums, MissionEnumsLists, OrderEnumsLists } from "../../../common/constants";
import { CalcProductionSum, CalcProductionTotal, CalcProductionVat, CalcToPaySum, FixNumber, CalcTotal, GetPaymentDate } from "../../../common/orders";
import { useMission } from "../../../providers/MissionProvider";
import moment from "moment/moment";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      width: "100%",
      height: "auto",
      background: theme.palette.background.grey,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(2)
    },
    table: {
      width: "100%",
      "& td": {
        border: "none",
        padding: theme.spacing(1),
        paddingLeft: 0,
        paddingBottom: theme.spacing(0.5),
        paddingTop: theme.spacing(0.5),
      },
    },
    sectionHeaderRow: {
      "& td": {
        marginTop: theme.spacing(3)
      }
    }
  })
);

export default function Receipt() {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const { entity } = useEntity();
  const { orderLines } = entity;
  const { orders, mission } = useMission();

  const { priceUnit, station } = useStation();

  const currency = t(`order:enums:currencyShortHand:${OrderEnumsLists.Currency[priceUnit]}`);

  const hasDeductible = !!entity.deductible;
  const hasVatTransferTo = !!entity.vatTransfer;

  const transferDeductibleEntity = orders.find(t => t.deductible?.order?.id === entity.id);
  const hasTransferDeductible = !!transferDeductibleEntity;

  const transferVatEntity = orders.find(t => t.vatTransfer?.order?.id === entity.id);
  const hasVatTransferFrom = !!transferVatEntity;

  const vatSum = FixNumber(CalcProductionVat(orderLines)); // TODO


  const targetType = mission.target.type;
  const targetDetails = targetType === 1 ? mission.target.carDetails : (targetType === 2 ? mission.target.boatDetails : null);


  return (
    <div className={classes.root}>
      <Grid container spacing={1} id="receipt">

        <Grid item xs={12}>
          <Typography variant="h6">{t("order:receipt:title")}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Table className={classes.table}>
            <TableBody>


              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">{t("order:receipt:seller")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{station?.legalName || "-"}</Typography>
                </TableCell>
              </TableRow>



              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">{t("entity:order:number")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{entity?.number || "-"}</Typography>
                </TableCell>
              </TableRow>


              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">{t("order:receipt:missionNumber")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{mission?.number || "-"}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">{t("order:receipt:missionType")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{t(`mission:enums:type:${MissionEnumsLists.Type[mission.details.type]}`)}</Typography>
                </TableCell>
              </TableRow>

              {
                mission.details.type === MissionEnums.Type.Salvage ? (
                  <TableRow>
                    <TableCell>
                      <Typography variant="body2" color="textSecondary">{t("order:receipt:cause")}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{mission.details.cause?.name || ""}</Typography>
                    </TableCell>
                  </TableRow>
                ) : null
              }

              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">{t(`mission:enums:targetType:${MissionEnumsLists.TargetType[mission.target.type]}`)}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{targetDetails?.make} {targetDetails?.model}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">{t("entity:mission:target:registration")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{targetDetails?.registration || "-"}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">{t("entity:order:payment:method")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{t(`order:enums:paymentMethod:${OrderEnumsLists.PaymentMethod[entity.payment.method || 0]}`)}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">{t("order:receipt:person")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{entity.payment.sender?.name || "-"}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">{t("common:comment")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{entity.payment?.comment || "-"}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">{t("order:receipt:agreementReference")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{mission?.actors?.contract?.reference || "-"}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">{t("order:receipt:date")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{moment(GetPaymentDate(entity.payment)).format("lll")}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">{t("order:receipt:created")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{moment(mission.created).format("lll")}</Typography>
                </TableCell>
              </TableRow>


              <TableRow className={classes.sectionHeaderRow}>
                <TableCell>
                  <Typography variant="body1" color="textSecondary">{t("order:receipt:productTitle")}</Typography>
                </TableCell>

                <TableCell>
                  <Typography variant="body1" color="textSecondary" textAlign="right">{t("order:receipt:sumTitle")}</Typography>
                </TableCell>

              </TableRow>

              <TableRow>
                <TableCell colSpan={2}>
                  <Divider />
                </TableCell>
              </TableRow>

              {orderLines.map((orderLine, index) => <OrderLineItem key={index} orderLine={orderLine} />)}

              <TableRow>
                <TableCell colSpan={2}>
                  <Divider />
                </TableCell>
              </TableRow>


              <TableRow>
                <TableCell>
                  <Typography variant="body1">{t("order:summary:production")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" textAlign="right">{FixNumber(CalcProductionSum(orderLines))} {currency}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body1">{t("order:summary:vat")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" textAlign="right">{vatSum} {currency}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={2}>
                  <Divider />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body1" fontWeight={500}>{t("order:summary:sumProduction")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" fontWeight={500} textAlign="right">{FixNumber(CalcProductionTotal(orderLines))} {currency}</Typography>
                </TableCell>
              </TableRow>

              {
                hasDeductible ? (
                  <TableRow>
                    <TableCell>
                      <Typography variant="body1" fontWeight={500}>
                        {t("entity:order:deductible")}
                      </Typography>

                    </TableCell>
                    <TableCell>
                      <Typography variant="body1" fontWeight={500} textAlign="right">
                        {FixNumber(entity.deductible.price.value || "0")} {t(`order:enums:currencyShortHand:${OrderEnumsLists.Currency[entity.deductible.price.currency]}`)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : null
              }

              {
                hasTransferDeductible ? (
                  <TableRow>
                    <TableCell>
                      <Typography variant="body1" fontWeight={500}>
                        {t("entity:order:deductible")}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1" fontWeight={500} textAlign="right">
                        -{FixNumber(transferDeductibleEntity.deductible.price.value || "0")} {t(`order:enums:currencyShortHand:${OrderEnumsLists.Currency[transferDeductibleEntity.deductible.price.currency]}`)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : null
              }

              {
                hasVatTransferTo ? (
                  <TableRow>
                    <TableCell>
                      <Typography variant="body1" fontWeight={500}>
                        {t("order:summary:vatTransfer")}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1" fontWeight={500} textAlign="right">
                        -{vatSum} {t(`order:enums:currencyShortHand:${OrderEnumsLists.Currency[priceUnit]}`)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : null
              }

              {
                hasVatTransferFrom ? (
                  <TableRow>
                    <TableCell>
                      <Typography variant="body1" fontWeight={500}>
                        {t("order:summary:vatTransfer")}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1" fontWeight={500} textAlign="right">
                        {FixNumber(CalcProductionVat(transferVatEntity.orderLines))} {t(`order:enums:currencyShortHand:${OrderEnumsLists.Currency[priceUnit]}`)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : null
              }

              <TableRow>
                <TableCell colSpan={2}>
                  <Divider />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body1" fontWeight={500}>{t("order:summary:sumToPay")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" fontWeight={500} textAlign="right">
                    {FixNumber(CalcToPaySum(entity, orders))} {currency}
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={2}>
                  <Divider />
                </TableCell>
              </TableRow>

            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </div>
  );
}




function OrderLineItem(props) {
  const { orderLine } = props;
  const { t } = useTranslation();
  const currency = t(`order:enums:currencyShortHand:${OrderEnumsLists.Currency[orderLine.price.currency]}`);

  const discounted = !!orderLine.discount && orderLine.discount !== "0";

  return (
    <TableRow>
      <TableCell>
        <Typography variant="body1">{orderLine.name}</Typography>
        <Typography variant="body2" color="textSecondary">
          {FixNumber(orderLine.quantity)} {t("order:orderLineItem:unit")} {t("order:orderLineItem:at")} {FixNumber(CalcTotal(orderLine.price, "1", orderLine.discount))} {currency}
          <br />{discounted ? `${orderLine.discount}% ${t("order:orderLineItem:discount")}` : ""}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="body1" textAlign="right">{FixNumber(CalcTotal(orderLine.price, orderLine.quantity, orderLine.discount))} {currency}</Typography>
        <Typography variant="body2" color="textSecondary" textAlign="right">{orderLine.productGroup.vatCode.rate}% {t("order:orderLineItem:vat")}</Typography>
      </TableCell>


    </TableRow>
  );
}