import { Location } from "@emberly/zenith-client";
import EventEmitter from "events";
import mapboxgl from "mapbox-gl";
import axios from "axios";
import { MapIcons } from "./constants";
import { HaversineDistance } from "./maphelpers";

export default class Route extends EventEmitter {

  constructor(locations = null, stationLocation = null) {
    super();
    this.locations = locations || [];
    this._stationLocation = stationLocation;
    this._rev = 0;
    this.locations.forEach(t => t.parent = this);
    this.addEmptyFn = () => this.add(new Location());
    this._routingLocations = null;
    this._routeFeatures = null;
    this._subRouteFeatures = null;
    this._simplifiedGeometryTreshold = 300000;

    this._vehicleWidth = 1.9;
    this._vehicleHeight = 1.6;
    this._vehicleWeight = 2500;

    this._overriddenVehicleWidth = null;
    this._overriddenVehicleHeight = null;
    this._overriddenVehicleWeight = null;

    this._updatedDimensions = false;
  }

  updateVehicleDataFromTask(task) {
    const route = task?.route;
    const vehicle = task?.execution?.vehicle;
    const overriddenWidth = route?.overriddenWidth;
    const overriddenHeight = route?.overriddenHeight;
    const overriddenWeight = route?.overriddenWeight;
    const width = vehicle?.width;
    const height = vehicle?.height;
    const weight = vehicle?.weight;
    this.updateVehicleData(width, height, weight, overriddenWidth || null, overriddenHeight || null, overriddenWeight || null);
  }

  updateVehicleData(width, height, weight, overriddenWidth, overriddenHeight, overriddenWeight) {

    if (
      this._vehicleWidth !== width || this._vehicleHeight !== height || this._vehicleWeight !== weight ||
      this._overriddenVehicleWidth !== overriddenWidth || this._overriddenVehicleHeight !== overriddenHeight || this._overriddenVehicleWeight !== overriddenWeight
    ) {
      this._routeFeatures = null;
      this._subRouteFeatures = null;
      this._updatedDimensions = true;
    }

    this._vehicleWidth = !!width ? Math.max(1, Math.min(10, width)) : this._vehicleWidth;
    this._vehicleHeight = !!height ? Math.max(1, Math.min(10, height)) : this._vehicleHeight;
    this._vehicleWeight = !!weight ? Math.max(1, Math.min(100000, weight)) : this._vehicleWeight;

    this._overriddenVehicleWidth = !!overriddenWidth ? Math.max(1, Math.min(10, overriddenWidth)) : null;
    this._overriddenVehicleHeight = !!overriddenHeight ? Math.max(1, Math.min(10, overriddenHeight)) : null;
    this._overriddenVehicleWeight = !!overriddenWeight ? Math.max(1, Math.min(100000, overriddenWeight)) : null;
  }

  get revision() {
    return this._rev;
  }

  get distance() {
    return !!this._routeFeatures?.distance ? Math.ceil(this._routeFeatures.distance / 1000.0) : -1;
  }

  get subRouteDistance() {
    return !!this._subRouteFeatures?.distance ? Math.ceil((this._subRouteFeatures.distance / 1000.0) * (this._routingLocations?.length > 1 ? 1 : 2)) : -1;
  }

  get metadata() {
    return {
      distanceInRouteKM: this.distance,
      distanceToRouteKM: this.subRouteDistance,
      loaded: !!this._routeFeatures || !!this._subRouteFeatures
    };
  }

  get duration() {
    return this._routeFeatures?.duration || -1;
  }

  get isEmpty() {
    return this.locations.length === 0 || !this.locations.find(t => !t.isEmpty);
  }

  get useStationLocation() {
    return !!this._stationLocation;
  }

  setStationLocation(stationLocation) {
    this._stationLocation = stationLocation;
    this._subRouteFeatures = null;
  }

  broadcast(origin) {
    this._rev++;
    this.emit("update", origin);
  }

  hasDelta(list) {
    if (list.length !== this.locations.length) return true;

    for (let i = 0; i < list.length; i++) {
      const a = list[i], b = this.locations[i];

      if (a.id !== b.id) {
        return true;
      }
    }

    return false;
  }

  hasRouteDelta() {
    if (this._routingLocations === null) return true;

    const validCoordinates = this.locations.filter(t => t.hasCoordinates);

    if (validCoordinates.length !== this._routingLocations.length) return true;

    for (let i = 0; i < validCoordinates.length; i++) {
      const a = validCoordinates[i], b = this._routingLocations[i];

      if (a.id !== b.id && !(!!a.mapboxId && a.mapboxId === b.mapboxId)) {
        return true;
      }
    }

    return false;
  }


  updateFromRawList(list) {
    if (!this._updatedDimensions && !this.hasDelta(list)) {
      return;
    }

    this._updatedDimensions = false;

    const clen = this.locations.length;
    const len = list.length;

    if (clen > len) {
      this.locations = this.locations.slice(0, len);
    } else if (clen < len) {
      const diff = len - clen;
      for (let i = 0; i < diff; i++) {
        this.locations.push(null);
      }
    }

    for (let i = 0; i < this.locations.length; i++) {
      const loc = new Location(list[i]);
      loc.parent = this;
      this.locations[i] = loc;
    }

    this.locations = [...this.locations];

    if (this.hasRouteDelta()) {
      this._routeFeatures = null;
      this._subRouteFeatures = null;
    }

    this.broadcast("updateFromRawList");
  }

  replace(oldLocation, newLocation) {
    const idx = this.locations.findIndex(t => t === oldLocation);
    this.locations[idx] = newLocation;
    oldLocation.parent = null;
    newLocation.parent = this;
    this.locations = [...this.locations];
    this._routeFeatures = null;
    this._subRouteFeatures = null;
    this.broadcast();
  }

  reorder(startIndex, endIndex) {
    const result = Array.from(this.locations);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    this.locations = result;
    this._routeFeatures = null;
    this._subRouteFeatures = null;
    this.broadcast();
  }

  remove(location) {
    this.locations = this.locations.filter(t => t !== location);
    this._routeFeatures = null;
    this._subRouteFeatures = null;
    this.broadcast();
  }

  add(location) {
    location.parent = this;
    this.locations.push(location);
    this.broadcast();
  }

  getRouteGeoJson() {
    return {
      "type": "FeatureCollection",
      "features": this._routeFeatures === null ? [] : [
        {
          "type": "Feature",
          "properties": {},
          "geometry": this._routeFeatures.geometry
        }
      ]
    };
  }

  getSubRouteGeoJson() {
    return {
      "type": "FeatureCollection",
      "features": this._subRouteFeatures === null ? [] : [
        {
          "type": "Feature",
          "properties": {},
          "geometry": this._subRouteFeatures.geometry
        }
      ]
    };
  }

  getWaypointsGeoJson(theme) {
    return {
      "type": "FeatureCollection",
      "features": (this.useStationLocation ? [...this.locations, this._stationLocation] : this.locations).filter(t => t.hasCoordinates).map((t, i, list) => t.getGeoJson(this.getWaypointProperties(i, list, theme)))
    };
  }

  getWaypointProperties(index, list, theme) {
    const len = list.length - 1;
    if (index === len && this.useStationLocation) {
      return {
        color: theme.palette.success.main,
        icon: MapIcons.Pins.Home
      }
    } else if (index === 0) {
      return {
        color: theme.palette.info.main,
        icon: MapIcons.Pins.Person
      };
    } else if (index === len - 1 && this.useStationLocation || index === len && !this.useStationLocation) {
      return {
        color: theme.palette.success.main,
        icon: MapIcons.Pins.Resolved
      };
    } else {
      return {
        color: theme.palette.info.main,
        icon: MapIcons.Pins.Moving
      };
    }
  }

  static EmptyGeoJson() {
    return {
      "type": "FeatureCollection",
      "features": []
    };
  }

  static GeoJson(features) {
    return {
      "type": "FeatureCollection",
      "features": features
    };
  }

  calcBoundsFromGeometry(bounds, geometry) {
    const list = geometry.coordinates;
    const len = list.length;

    for (let i = 0; i < len; i++) {
      bounds.extend(list[i]);
    }
  }

  getBounds() {
    const bounds = new mapboxgl.LngLatBounds();

    if (this._subRouteFeatures !== null || this._routeFeatures !== null) {

      if (this._subRouteFeatures !== null) {
        this.calcBoundsFromGeometry(bounds, this._subRouteFeatures.geometry);
      }

      if (this._routeFeatures !== null) {
        this.calcBoundsFromGeometry(bounds, this._routeFeatures.geometry);
      }

      return bounds;
    }

    const coords = this.locations.filter(t => t.hasCoordinates);

    if (this.useStationLocation) {
      coords.push(this._stationLocation);
    }

    if (coords.length === 0) return null;

    coords.forEach(c => bounds.extend(c.coordinates));

    return bounds;
  }


  async loadRouting(forceFullRoute = false) {
    const validCoordinates = this.locations.filter(t => t.hasCoordinates);
    const validLen = validCoordinates.length;
    let diff = false;

    if (validLen >= 2 && this._routeFeatures === null) {
      this._routingLocations = validCoordinates;
      this._routeFeatures = await this.fetchRoute(
        validCoordinates,
        this._overriddenVehicleHeight || this._vehicleHeight,
        this._overriddenVehicleWidth || this._vehicleWidth,
        this._overriddenVehicleWeight || this._vehicleWeight,
        forceFullRoute
      );
      diff = true;
    }

    if (validLen >= 1 && this.useStationLocation && this._subRouteFeatures === null) {
      this._routingLocations = validCoordinates;
      this._subRouteFeatures = await this.fetchRoute(
        validLen === 1 ?
          [validCoordinates[0], this._stationLocation] :
          [validCoordinates[0], this._stationLocation, validCoordinates[validLen - 1]],
        this._vehicleHeight,
        this._vehicleWidth,
        this._vehicleWeight,
        forceFullRoute
      );
      diff = true;
    }

    if (diff) {
      this.emit("metadata", this.metadata);
    }
  }

  async fetchRoute(waypoints, maxHeight = 1.6, maxWidth = 1.9, maxWeight = 2.5, forceFullRoute = false) {
    const queryCoordinates = waypoints.map(t => t.coordinates.join(",")).join(";");
    const query = `driving/${queryCoordinates}?access_token=${mapboxgl.accessToken}&alternatives=false&geometries=geojson&max_height=${maxHeight}&max_width=${maxWidth}&max_weight=${maxWeight/1000.0}`;
    
    const cacheKey = `${query}:${forceFullRoute}`;
    const cachedRoute = this.tryFetchCachedRoute(cacheKey);
    
    if (!!cachedRoute) {
      return cachedRoute;
    }
    
    const maxDistance = this.getMaxRouteDistance(waypoints);

    const res = await axios(
      `https://api.mapbox.com/directions/v5/mapbox/${query}&overview=${!forceFullRoute && maxDistance > this._simplifiedGeometryTreshold ? "simplified" : "full"}`
    );

    if (res.data?.routes?.length !== 0) {
      const data = res.data.routes[0];
      this.tryStoreRouteInCache(cacheKey, data);
      return data;
    }

    return null;
  }

  getMaxRouteDistance(waypoints) {

    let maxDistance = 0;

    for (let i = 0; i < waypoints.length; i++) {

      let wp0 = waypoints[i];

      for (let j = 0; j < waypoints.length; j++) {

        if (j === i) continue;

        let wp1 = waypoints[j];
        const dist = HaversineDistance(wp0.coordinates[0], wp0.coordinates[1], wp1.coordinates[0], wp1.coordinates[1]);

        if (dist > maxDistance) {
          maxDistance = dist;
        }
      }
    }

    return maxDistance;
  }


  tryFetchCachedRoute(queryCoordinates) {
    try {
      const data = sessionStorage.getItem("routing_" + queryCoordinates);

      if (!!data) {
        return JSON.parse(data);
      }

    } catch (err) {
      console.log(err);
    }
    return null;
  }

  tryStoreRouteInCache(queryCoordinates, data) {
    try {
      sessionStorage.setItem("routing_" + queryCoordinates, JSON.stringify(data));
    } catch (err) {
      console.log(err);
    }
  }


  fetchChronologicalRoute() {
    const validCoordinates = this.locations.filter(t => t.hasCoordinates);
    const validLen = validCoordinates.length;

    if (validLen === 0) return null;

    let route = this._subRouteFeatures?.geometry?.coordinates;
    
    if (validLen === 1) {
      return [...route].reverse().concat(route);
    }

    let transport = this._routeFeatures?.geometry?.coordinates;

    const { a, b } = this.splitRouteByWaypoint(route, this._stationLocation);

    let routeTo = a.reverse();
    let routeFrom = b.reverse();

    return routeTo.concat(transport).concat(routeFrom);
  }


  splitRouteByWaypoint(route, waypoint) {
    let longitude = waypoint.coordinates[0];
    let latitude = waypoint.coordinates[1];
    let minDistance = 99999999;
    let minIndex = -1;

    for (let i = 0; i < route.length; i++) {
      let coordinates = route[i];
      const dist = HaversineDistance(longitude, latitude, coordinates[0], coordinates[1]);

      if (dist < minDistance) {
        minDistance = dist;
        minIndex = i;
      }
    }

    return {
      a: route.slice(0, minIndex),
      b: route.slice(minIndex)
    };
  }

}