import { Grid, Button, DialogActions, DialogContent, DialogTitle, Divider, FormControl, InputLabel, Select, MenuItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useEntities } from "@emberly/zenith-client";
import ResponsiveDialog from "../../../../../common/core/ResponsiveDialog";

export default function VehicleSelectDialog(props) {
  const { task, value } = props;
  const { t } = useTranslation();

  const [vehicleId, setVehicleId] = useState(value?.id || "");
  const { entities: vehicles } = useEntities("ServiceVehicle");

  const onConfirm = () => {
    if (!vehicleId) {
      task.complete({ id: null, name: null });
    } else {
      const data = vehicles?.find(t => t.id === vehicleId);

      task.complete(!!data ? {
        id: data.id,
        name: data.title,
        width: data.width,
        height: data.height,
        weight: data.weight,
        length: data.length,
        emissionClass: data.emissionClass,
        fuel: data.fuel
      } : { id: null, name: null });
    }
  }

  const vehicleDoesntExist = !!vehicleId && !vehicles.find(t => t.id === vehicleId);

  return (
    <ResponsiveDialog
      open
      onClose={() => task.throw()}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {t("vehicleSelector:activeTitle")}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>

          <Grid item xs={12}>
            <Typography variant="body2">
              {t("vehicleSelector:activeDescription")}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth size="small" variant="filled">
              <InputLabel>{t("vehicleSelector:title")}</InputLabel>
              <Select
                onChange={ev => setVehicleId(ev.target.value)}
                value={vehicleId || ""}
              >

                <MenuItem value="">{t("vehicleSelector:none")}</MenuItem>

                <Divider />

                {vehicleDoesntExist ? (
                  <MenuItem value={vehicleId}>{value?.name}</MenuItem>
                ) : null}

                {vehicles.map((c, key) => <MenuItem value={c.id} key={key}>{c.title}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => task.throw()}>
          {t("common:cancel")}
        </Button>
        <Button variant="contained" color="primary" onClick={onConfirm}>
          {!!value?.id ? t("common:change") : t("common:save")}
        </Button>
      </DialogActions>
    </ResponsiveDialog>
  );
}
