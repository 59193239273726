import { makeStyles } from "tss-react/mui";
import { Grid, Button, DialogTitle, DialogContent, DialogActions, TextField, TableBody, Table, TableCell, Typography, TableRow, Select, InputLabel, FormControl, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEntity, useEntities } from "@emberly/zenith-client";
import { useState } from "react";
import { useStation } from "../../../../providers/StationProvider";
import { GetVatCode, MakeFreeTextOrderLineItem } from "../../../../common/orders";
import { OrderEnumsLists } from "../../../../common/constants";
import TextFieldDecimal from "../../inputs/TextFieldDecimal";
import ResponsiveDialog from "../../core/ResponsiveDialog";

const useStyles = makeStyles()(
  (theme) => ({
    table: {
      width: "100%",
      "& td": {
        border: "none",
        padding: theme.spacing(1),
        paddingLeft: 0,
        paddingBottom: theme.spacing(0.25),
        paddingTop: theme.spacing(0.25),
        verticalAlign: "top"
      }
    }
  })
);

export default function FreeTextOrderLineItemDialog(props) {
  const { edit, onClose, index, orderLine } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { pushToList, updateEntityField, spliceList } = useEntity();
  const { priceUnit } = useStation();
  const [productName, setProductName] = useState(edit && !!orderLine ? orderLine.name : "");
  const [price, setPrice] = useState(edit && !!orderLine ? orderLine.price.value : "0");
  const [quantity, setQuantity] = useState(edit && !!orderLine ? orderLine.quantity : "1");
  const [discount, setDiscount] = useState(edit && !!orderLine ? orderLine.discount : "0");
  const [currency, setCurrency] = useState(edit && !!orderLine ? orderLine.price.currency : priceUnit);
  const [productGroup, setProductGroup] = useState(edit && !!orderLine ? orderLine.productGroup : "");

  const { entities: vatCodes } = useEntities("VatCode");
  const { entities: productGroups } = useEntities("ProductGroup");


  const onSubmit = () => {
    const item = MakeFreeTextOrderLineItem(productName, { value: price, currency }, quantity, discount, productGroup, vatCodes);
    if (edit) {
      updateEntityField(`orderLines.${index}`, item);
    } else {
      pushToList("orderLines", item);
    }
    onClose();
  };

  const onDelete = () => {
    spliceList("orderLines", index);
    onClose();
  };

  const vatCode = GetVatCode(productGroup.id, productGroups, vatCodes);
  const group = productGroups.find(t => t.id === productGroup?.id);

  return (
    <ResponsiveDialog
      open
      onClose={onClose}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>
        {edit ? t("order:freeTextOrderLineItemDialog:editTitle") : t("order:freeTextOrderLineItemDialog:createTitle")}
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={1}>

          <Grid item xs={12}>
            <TextField
              label={t("order:freeTextOrderLineItemDialog:productName")}
              onChange={ev => setProductName(ev.target.value)}
              value={productName}
              variant="filled"
              fullWidth
              size="small"
            />
          </Grid>

          <Grid item xs={12}>
            <TextFieldDecimal
              label={t("order:freeTextOrderLineItemDialog:price")}
              onChange={ev => setPrice(ev.target.value)}
              value={price}
              variant="filled"
              fullWidth
              selectOnFocus
              decimal
              size="small"
              suffix={` ${t(`order:enums:currencyShortHand:${OrderEnumsLists.Currency[currency]}`)}`}
            />
          </Grid>


          <Grid item xs={12}>
            <TextFieldDecimal
              label={t("order:freeTextOrderLineItemDialog:quantity")}
              onChange={ev => setQuantity(ev.target.value)}
              value={quantity}
              selectOnFocus
              decimal
              variant="filled"
              fullWidth
              size="small"
            />
          </Grid>

          <Grid item xs={12}>
            <TextFieldDecimal
              label={t("order:freeTextOrderLineItemDialog:discount")}
              onChange={ev => setDiscount(ev.target.value)}
              value={discount}
              variant="filled"
              selectOnFocus
              decimal
              fullWidth
              size="small"
              suffix=" %"
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth size="small" variant="filled">
              <InputLabel>{t("order:freeTextOrderLineItemDialog:productGroup")}</InputLabel>
              <Select
                value={productGroup?.id || ""}
                onChange={(ev) => setProductGroup(productGroups.find(t => t.id === ev.target.value))}
              >
                {
                  productGroups.map((group, index) => (
                    <MenuItem value={group.id} key={index}>{group.name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Table className={classes.table}>
              <TableBody>

                <TableRow>
                  <TableCell>
                    <Typography variant="body2" color="textSecondary">{t("order:freeTextOrderLineItemDialog:vat")}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">{!!vatCode ? `${vatCode.rate || "0"}% ` : "-"} {!!vatCode && !!vatCode?.code ? `(${t("order:freeTextOrderLineItemDialog:code")} ${vatCode.code})` : ""}</Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography variant="body2" color="textSecondary">{t("order:freeTextOrderLineItemDialog:description")}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">{group?.description || "-"}</Typography>
                  </TableCell>
                </TableRow>

              </TableBody>
            </Table>
          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions>
        {!!edit ? (
          <Button onClick={onDelete}>
            {t("common:remove")}
          </Button>
        ) : null}
        <Button onClick={onClose} variant={!!edit ? "outlined" : undefined}>
          {t("common:cancel")}
        </Button>
        <Button variant="contained" color="primary" onClick={onSubmit} disabled={!productName || !productGroup}>
          {edit ? t("common:change") : t("common:add")}
        </Button>
      </DialogActions>
    </ResponsiveDialog>
  );
}


