import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from 'i18next-http-backend';
import config from "./config";


i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(HttpApi)
  .init({
    load: 'languageOnly',
    lng: "no", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    initImmediate: false,
    fallbackLng: [
      "no"
    ],
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    backend: {
      loadPath: `/lang/{{lng}}/translations-v${config.langVersion}.json`,
    },
    keySeparator: ":"
  });

export default i18n;