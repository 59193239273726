import { Grid, Button, MenuItem, FormControl, DialogTitle, DialogContent, DialogActions, InputLabel, Select, InputAdornment, Divider, ListItemIcon, Alert } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useEntity, useEntityFunctions } from "@emberly/zenith-client";
import { useState } from "react";
import { useStation } from "../../../../providers/StationProvider";
import { OrderEnums, OrderEnumsLists } from "../../../../common/constants";
import { useMission } from "../../../../providers/MissionProvider";
import { CanChangePaymentMethod, MakeInvoiceInfo } from "../../../../common/orders";
import TextFieldDecimal from "../../inputs/TextFieldDecimal";
import { positive } from "../../../../common/helpers";
import ResponsiveDialog from "../../core/ResponsiveDialog";


export default function DeductibleDialog(props) {
  const { onClose } = props;
  const { t } = useTranslation();
  const { updateEntityField, entity } = useEntity();
  const { priceUnit } = useStation();
  const { orders, makeOrder, mission } = useMission();
  const { updateEntity } = useEntityFunctions("Order");

  const edit = !!entity.deductible;

  const [transferOrder, setTransferOrder] = useState(edit ? entity.deductible.order.id : "");
  const [price, setPrice] = useState(edit ? entity.deductible.price.value || "" : (mission?.actors?.contract?.deductible?.value || "0"));

  const availableOrders = orders.filter(t => t.id === transferOrder || t.id === entity?.deductible?.order?.id || (t.id !== entity.id && !t.deductible && CanChangePaymentMethod(t.payment) && !orders.find(c => c.deductible?.order?.id === t.id)));
  const selectedOrder = availableOrders.find(t => t.id === transferOrder);
  const isNotInvoice = !!selectedOrder && selectedOrder?.payment?.method !== OrderEnums.PaymentMethod.Invoice;

  const onSubmit = async () => {
    if (transferOrder === "create") {
      const order = await makeOrder({ invoice: MakeInvoiceInfo(mission), method: OrderEnums.PaymentMethod.Invoice });

      if (!!order) {
        updateEntityField("deductible", { order: { id: order.id, name: order.name }, price: { currency: priceUnit, value: price } });
        onClose();
      }

    } else {
      const order = availableOrders.find(t => t.id === transferOrder);
      updateEntityField("deductible", { order: { id: order.id, name: order.name }, price: { currency: priceUnit, value: price } });

      if (isNotInvoice) {
        updateEntity({ id: order.id, payment: { invoice: MakeInvoiceInfo(mission), method: OrderEnums.PaymentMethod.Invoice } }, ["payment.invoice", "payment.request", "payment.terminal", "payment.method"]);
      }

      onClose();
    }
  };

  const onDelete = () => {
    updateEntityField("deductible", null);
    onClose();
  };

  return (
    <ResponsiveDialog
      open
      onClose={onClose}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>
        {t("order:deductibleDialog:title")}
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={1}>

          {
            !!isNotInvoice ? (
              <Grid item xs={12}>
                <Alert severity="info">
                  {t("order:deductibleDialog:convertToInvoice")}
                </Alert>
              </Grid>
            ) : null
          }

          <Grid item xs={12}>
            <TextFieldDecimal
              label={t("entity:order:deductible")}
              onChange={ev => setPrice(positive(ev.target.value))}
              value={price}
              variant="filled"
              selectOnFocus
              decimal
              fullWidth
              size="small"
              suffix={` ${t(`order:enums:currencyShortHand:${OrderEnumsLists.Currency[priceUnit]}`)}`}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth size="small" variant="filled">
              <InputLabel>{t("order:deductibleDialog:transfer")}</InputLabel>
              <Select onChange={ev => setTransferOrder(ev.target.value)} value={transferOrder}>
                <MenuItem value="">{t("order:deductibleDialog:none")}</MenuItem>
                <Divider />
                <MenuItem value="create">
                  {
                    transferOrder !== "create" ? (
                      <ListItemIcon>
                        <AddIcon fontSize="small" />
                      </ListItemIcon>
                    ) : null
                  }
                  {t("order:deductibleDialog:create")}
                </MenuItem>
                <Divider />
                {availableOrders.map((order, idx) => <MenuItem selected={order.id === transferOrder} value={order.id} key={idx}>{t("order:deductibleDialog:order")} {order.name}{!!order?.customer?.name ? " - " : ""}{order.customer?.name || ""}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>


        </Grid>
      </DialogContent>
      <DialogActions>
        {!!edit ? (
          <Button onClick={onDelete}>
            {t("common:remove")}
          </Button>
        ) : null}
        <Button onClick={onClose} variant={!!edit ? "outlined" : undefined}>
          {t("common:cancel")}
        </Button>
        <Button variant="contained" color="primary" onClick={onSubmit} disabled={!transferOrder || !price || price === "0"}>
          {t("common:save")}
        </Button>
      </DialogActions>
    </ResponsiveDialog>
  );
}


