import { useMemo, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Grid, Typography, Button, Divider, Table, TableBody, TableRow, TableCell, ButtonBase } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Edit as EditIcon, Add as AddIcon } from "@mui/icons-material";
import { useStation } from "../../../providers/StationProvider";
import { OrderEnumsLists } from "../../../common/constants";
import { CalcProductionSum, CalcProductionTotal, CalcProductionVat, CalcToPaySum, FixNumber } from "../../../common/orders";
import DeductibleDialog from "./dialogs/DeductibleDialog";
import { useMission } from "../../../providers/MissionProvider";
import VatTransferDialog from "./dialogs/VatTransferDialog";
import { useOrder } from "../../../providers/OrderProvider";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      width: "100%",
      height: "auto",
      background: theme.palette.background.grey,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(2)
    },
    table: {
      width: "100%",
      "& td": {
        border: "none",
        padding: theme.spacing(1),
        paddingLeft: 0,
        paddingBottom: theme.spacing(0.25),
        paddingTop: theme.spacing(0.25),
      },
    },
    emptyState: {
      margin: theme.spacing(1)
    },
    buttonCell: {
      borderRadius: theme.spacing(0.5),
      "& svg": {
        marginBottom: "-1px"
      }
    },
    button: {
      marginTop: theme.spacing(0.5)
    }
  })
);

export default function OrderSummary(props) {
  const { disabled } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();

  const { order, open } = useOrder();
  const { orderLines } = order;
  const { orders } = useMission();

  const { priceUnit } = useStation();
  const currency = t(`order:enums:currencyShortHand:${OrderEnumsLists.Currency[priceUnit]}`);

  const [deductibleDialog, setDeductibleDialog] = useState(false);
  const [vatTransferDialog, setVatTransferDialog] = useState(false);

  const { 
    hasDeductible,
    hasVatTransferTo,
    transferDeductibleEntity,
    transferVatEntity,
    hasTransferDeductible,
    hasVatTransferFrom,
    vatSum,
   } = useMemo(() => {
    const transferDeductibleEntity = orders.find(t => t.deductible?.order?.id === order.id);
    const transferVatEntity = orders.find(t => t.vatTransfer?.order?.id === order.id);

    return {
      hasDeductible: !!order.deductible,
      hasVatTransferTo: !!order.vatTransfer,
      transferDeductibleEntity,
      transferVatEntity,
      hasTransferDeductible: !!transferDeductibleEntity,
      hasVatTransferFrom: !!transferVatEntity,
      vatSum: FixNumber(CalcProductionVat(orderLines)), 
    }
  }, [orders, order, orderLines]);

  const buttonBreakpoint = (hasDeductible || hasTransferDeductible || hasVatTransferTo || hasVatTransferFrom) ? 12 : 6;

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Table className={classes.table}>
            <TableBody>

              <TableRow>
                <TableCell>
                  <Typography variant="body1">{t("order:summary:production")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" textAlign="right">{FixNumber(CalcProductionSum(orderLines))} {currency}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body1">{t("order:summary:vat")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" textAlign="right">{vatSum} {currency}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={2}>
                  <Divider />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body1" fontWeight={500}>{t("order:summary:sumProduction")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" fontWeight={500} textAlign="right">{FixNumber(CalcProductionTotal(orderLines))} {currency}</Typography>
                </TableCell>
              </TableRow>

              {
                hasDeductible ? (
                  <TableRow>
                    <TableCell>
                      <ButtonBase onClick={() => setDeductibleDialog(true)} className={classes.buttonCell} disabled={!open || disabled}>
                        <Typography variant="body1" color="secondary.main" fontWeight={500} textAlign="left">
                          {t("entity:order:deductible")} {t("order:summary:to")} {t("order:summary:order")} {order.deductible?.order?.name || "-"} {open ? <EditIcon fontSize="inherit" /> : null}
                        </Typography>
                      </ButtonBase>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1" color="secondary.main" fontWeight={500} textAlign="right">
                        {FixNumber(order.deductible?.price?.value || "0")} {t(`order:enums:currencyShortHand:${OrderEnumsLists.Currency[order.deductible?.price?.currency || 0]}`)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : null
              }

              {
                hasTransferDeductible ? (
                  <TableRow>
                    <TableCell>
                      <Typography variant="body1" color="secondary.main" fontWeight={500}>
                        {t("entity:order:deductible")} {t("order:summary:from")} {t("order:summary:order")} {transferDeductibleEntity.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1" color="secondary.main" fontWeight={500} textAlign="right">
                        -{FixNumber(transferDeductibleEntity.deductible?.price?.value || "0")} {t(`order:enums:currencyShortHand:${OrderEnumsLists.Currency[transferDeductibleEntity.deductible?.price?.currency || 0]}`)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : null
              }

              {
                hasVatTransferTo ? (
                  <TableRow>
                    <TableCell>
                      <ButtonBase onClick={() => setVatTransferDialog(true)} className={classes.buttonCell} disabled={!open || disabled}>
                        <Typography variant="body1" color="secondary.main" fontWeight={500} textAlign="left">
                          {t("order:summary:vatTransfer")} {t("order:summary:to")} {t("order:summary:order")} {order.vatTransfer?.order?.name || "-"} {open && !disabled ? <EditIcon fontSize="inherit" /> : null}
                        </Typography>
                      </ButtonBase>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1" color="secondary.main" fontWeight={500} textAlign="right">
                        -{vatSum} {t(`order:enums:currencyShortHand:${OrderEnumsLists.Currency[priceUnit]}`)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : null
              }

              {
                hasVatTransferFrom ? (
                  <TableRow>
                    <TableCell>
                      <Typography variant="body1" color="secondary.main" fontWeight={500}>
                        {t("order:summary:vatTransfer")} {t("order:summary:from")} {t("order:summary:order")} {transferVatEntity.vatTransfer?.order?.name || "-"}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1" color="secondary.main" fontWeight={500} textAlign="right">
                        {FixNumber(CalcProductionVat(transferVatEntity.orderLines))} {t(`order:enums:currencyShortHand:${OrderEnumsLists.Currency[priceUnit]}`)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : null
              }

              <TableRow>
                <TableCell colSpan={2}>
                  <Divider />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body1" fontWeight={500}>{t("order:summary:sumToPay")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" fontWeight={500} textAlign="right">
                    {FixNumber(CalcToPaySum(order, orders))} {currency}
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={2}>
                  <Divider />
                </TableCell>
              </TableRow>

            </TableBody>
          </Table>
        </Grid>

        {
          !hasDeductible && !hasTransferDeductible ? (
            <Grid item xs={buttonBreakpoint}>
              <Button color="neutral" variant="contained" startIcon={<AddIcon />} fullWidth onClick={() => setDeductibleDialog(true)} className={classes.button} disabled={!open}>
                {t("entity:order:deductible")}
              </Button>
            </Grid>
          ) : null
        }

        {
          !hasVatTransferTo && !hasVatTransferFrom ? (
            <Grid item xs={buttonBreakpoint}>
              <Button color="neutral" variant="contained" startIcon={<AddIcon />} fullWidth onClick={() => setVatTransferDialog(true)} className={classes.button} disabled={!open}>
                {t("order:summary:addVatMoving")}
              </Button>
            </Grid>
          ) : null
        }

      </Grid>

      {deductibleDialog ? (
        <DeductibleDialog onClose={() => setDeductibleDialog(false)} />
      ) : null}

      {vatTransferDialog ? (
        <VatTransferDialog onClose={() => setVatTransferDialog(false)} />
      ) : null}

    </div>
  );
}
