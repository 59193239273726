import { Button, Snackbar } from "@mui/material";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useEntityFunctions, useAuth, Task } from "@emberly/zenith-client";
import AlertDialog from "../components/common/inputs/AlertDialog";
import { useSnackbar } from 'notistack';

export const PushContext = createContext();

export const useNotification = () => useContext(PushContext);

export const NotificationContextProvider = (props) => {
  const { vapidPublicKey } = props;
  const { t } = useTranslation();
  const { emitServiceEvent, createEntity } = useEntityFunctions("PushNotification");

  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();

  const [showRequest, setShowRequest] = useState(false);
  const [requestConfirmed, setRequestConfirmed] = useState(false);
  const [registration, setRegistration] = useState(null);
  const [alerts, setAlerts] = useState([]);

  const onClose = useCallback(() => {
    setShowRequest(false);
  }, []);

  const getSubscription = useCallback((reg) => {
    const establishEntity = (sub) => {
      const auth = arrayBufferToBase64(sub.getKey("auth"));
      const p256dh = arrayBufferToBase64(sub.getKey("p256dh"));

      createEntity({
        id: `${user.org_id}|${user.sub}|${auth}`,
        userId: user.sub,
        subscription: {
          endpoint: sub.endpoint,
          p256dh,
          auth,
        }
      });
    };

    reg.pushManager.getSubscription().then(function (sub) {
      if (sub === null) {
        reg.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: vapidPublicKey
        }).then(function (sub) {
          establishEntity(sub);
        }).catch(function (e) {
          console.error("Unable to subscribe to push", e);
        });
      } else {
        //establishEntity(sub); ?? should we push here too?
      }
    });
  }, [vapidPublicKey, createEntity, user]);

  useEffect(() => {
    // https://blog.elmah.io/how-to-send-push-notifications-to-a-browser-in-asp-net-core/
    // next session
    if ("serviceWorker" in navigator && "Notification" in window) {
      navigator.serviceWorker.ready
        .then((reg) => {
          setRegistration(reg);
          if (Notification.permission === "granted") {
            getSubscription(reg);
          } else if (Notification.permission === "blocked" || Notification.permission === "denied") {
            //$("#NoSupport").show();
          } else {
            setShowRequest(true);
          }
        });
    }
  }, [getSubscription]);

  const onRequestAccess = useCallback(() => {
    setShowRequest(false);
    setRequestConfirmed(true)
    Notification.requestPermission(function (status) {
      if (status === "granted") {
        getSubscription(registration);
        // TODO show notification that this was done
      }
    });
  }, [registration, getSubscription]);


  const push = useCallback((userId, message) => {
    const payload = typeof message === "string" ? message : JSON.stringify(message);

    if (userId.startsWith(user.org_id)) {
      emitServiceEvent("Notify", { userId: userId.substring(user.org_id.length + 1), payload });
    } else {
      emitServiceEvent("Notify", { userId, payload });
    }
  }, [emitServiceEvent, user]);


  const alert = useCallback(async (title = "alert", description = "", color = "error") => {
    const t = new Task();
    const data = { task: t, title, description, color };
    setAlerts(list => [...list, data]);
    await t.wait();
    setAlerts(list => list.filter(t => t !== data));
  }, []);


  const snackbar = useCallback((message = "", variant = undefined) => {
    enqueueSnackbar(message, { variant });
  }, [enqueueSnackbar]);


  return (
    <PushContext.Provider
      value={{
        push,
        alert,
        snackbar
      }}
    >

      {props.children}

      <Snackbar
        open={showRequest && !requestConfirmed}
        autoHideDuration={15000}
        onClose={onClose}
        message={t("notification:requestAccessText")}
        action={
          <Button color="inherit" onClick={onRequestAccess}>
            {t("notification:requestAccessAction")}
          </Button>
        }
      />

      {
        alerts.length !== 0 ? (
          <AlertDialog
            task={alerts[0].task}
            title={alerts[0].title}
            description={alerts[0].description}
            color={alerts[0].color}
          />
        ) : null
      }



    </PushContext.Provider>
  );
};



function arrayBufferToBase64(buffer) {
  let binary = "";
  let bytes = new Uint8Array(buffer);
  let len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}