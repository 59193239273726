import { makeStyles } from "tss-react/mui";
import { Grid, Typography, IconButton, CircularProgress, List, ListItem, ListItemAvatar, ListItemText, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMission } from "../../../providers/MissionProvider";
import { Fragment, useCallback, useMemo, useRef, useState } from "react";
import { Delete as DeleteIcon, Description as FileIcon, DownloadOutlined as DownloadIcon } from "@mui/icons-material";
import { Task } from "@emberly/zenith-client";
import ConfirmDialog from "../inputs/ConfirmDialog";
import moment from "moment/moment";
import { useDevice } from "../../../providers/DeviceProvider";
import EmptyState from "../core/EmptyState";
import DropTarget from "../core/DropTarget";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Share from "yet-another-react-lightbox/plugins/share";
import Download from "yet-another-react-lightbox/plugins/download";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import config from "../../../config";
import { GetLightboxSlide } from "../../../common/mission";
import axios from "axios";

const PLUGINS = [Captions, Thumbnails, Zoom, Share, Download];
const ANIMATION = { fade: 250, swipe: 250, easing: { fade: "ease", swipe: "ease-out", navigation: "ease-in-out" } };

const LIGHTBOX_STYLES = {
  icon: {
    width: "24px",
    height: "24px"
  },
  container: {
    backgroundColor: "rgba(0, 0, 0, 0.82)",
    backdropFilter: "blur(4px)"
  },
  captionsTitleContainer: {
    background: "none"
  },
  captionsTitle: {
    fontSize: "14px",
    fontFamily: `IBM Plex Sans", Arial`
  },
  thumbnailsContainer: {
    backgroundColor: "rgba(0, 0, 0, 0.875)",
    backdropFilter: "blur(8px)"
  },
  thumbnailsTrack: {
    background: "none",
  }
};

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      position: "relative"
    },
    emptyContainer: {
      border: `3px solid ${theme.palette.divider}`,
      borderRadius: theme.spacing(1),
      borderStyle: "dashed",
    },
    clickableFileUpload: {
      position: "absolute",
      top: 0,
      left: 0,
      height: "100%",
      width: "100%",
      opacity: 0,
      zIndex: 2
    },
    imageContainer: {
      position: "relative",
      width: "100%",
      height: "152px",
      background: theme.palette.background.grey,
      borderRadius: theme.spacing(1),
      "&:after": {
        zIndex: 3,
        position: "absolute",
        height: "100%",
        width: "100%",
        top: 0,
        right: 0,
        opacity: 0,
        background: theme.palette.action.hover,
        borderRadius: theme.spacing(1),
        content: "''",
        display: "block",
        pointerEvents: "none"
      },
      "&:hover:after": {
        opacity: 1
      }
    },
    image: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: theme.spacing(1),
      cursor: "pointer"
    },
    deleteImageButton: {
      position: "absolute",
      top: "4px",
      right: "4px",
      zIndex: 3,
      background: "rgba(255,255,255,0.77)",
      "&:hover": {
        background: "rgba(255,255,255,1.0)"
      }
    },
    imageProgressContainer: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.spacing(1),
      background: theme.palette.background.grey
    },
    documentContainer: {
      borderRadius: theme.spacing(1),
      cursor: "pointer",
      "&:after": {
        zIndex: 3,
        position: "absolute",
        height: "100%",
        width: "100%",
        top: 0,
        right: 0,
        opacity: 0,
        background: theme.palette.action.hover,
        borderRadius: theme.spacing(1),
        content: "''",
        display: "block",
        pointerEvents: "none"
      },
      "&:hover:after": {
        opacity: 1
      }
    }
  })
);

export default function FilesSection() {
  const { t } = useTranslation();
  const [deleteAction, setDeleteAction] = useState(null);
  const { slim } = useDevice();
  const { classes } = useStyles();

  const ref = useRef(null);

  const [lightboxIndex, setLightboxIndex] = useState(0);
  const [lightboxOpen, setLightboxOpen] = useState(false);

  const { deleteFile, mission, revision, uploadingFiles, setUploadingFiles, onSelectFiles, locked } = useMission();
  const uploads = mission?.files?.uploads;
  const [dragOver, setDragOver] = useState(0);

  const onDragEnter = useCallback((ev) => {
    try {
      setDragOver(t => t + 1);
    } catch (err) {
      console.log(err)
      setDragOver(t => t + 1);
    }
  }, [setDragOver]);

  const onDragLeave = useCallback((ev) => {
    try {
      setDragOver(t => Math.max(t - 1, 0));
    } catch (err) {
      console.log(err)
      setDragOver(t => Math.max(t - 1, 0));
    }
  }, [setDragOver]);

  const onDragOver = useCallback((ev) => ev.preventDefault(), []);

  const onDrop = useCallback(async (ev) => {
    ev.preventDefault();
    setDragOver(0);

    const files = [...ev.dataTransfer.items].filter(t => t.kind === "file").map(t => t.getAsFile()).reverse();

    if (files.length !== 0 && !locked) {
      onSelectFiles(ev, files);
    }

  }, [setDragOver, onSelectFiles, locked]);

  const { images, documents, slides } = useMemo(() => {

    const buff = new Set(uploadingFiles);

    const files = Object.values(uploads)
      .filter(f => !!f)
      .map(f => {
        const u = uploadingFiles.find(t => t.id === f.id);

        if (!!u) {
          buff.delete(u);
          return { ...f, loaded: u.loaded, total: u.total, deleting: u.deleting }
        }

        return f;
      }).concat([...buff]);

    const images = files.filter(t => t.type === 1);
    const documents = files.filter(t => t.type !== 1);
    const slides = images.filter(img => !!img.complete && !!img.url).map(img => GetLightboxSlide(img));

    return {
      images,
      documents,
      slides
    }
  }, [uploads, mission, uploadingFiles, revision]);

  const onDeleteFile = useCallback(async (ev, fileId) => {
    try {
      ev.preventDefault();
      ev.stopPropagation();

      const task = new Task();
      setDeleteAction(task);
      await task.wait();
      setUploadingFiles(l => [...l.filter(t => t.id !== fileId), { id: fileId, deleting: true }])
      await deleteFile(fileId);
      setUploadingFiles(l => l.filter(t => t.id !== fileId));

    } catch (err) {
      console.log(err);
    } finally {
      setDeleteAction(null);
    }
  }, [deleteFile, setUploadingFiles]);

  const isEmpty = images.length === 0 && documents.length === 0;

  const onDownloadOriginal = useCallback(() => {
    const slide = slides[lightboxIndex];
    downloadURI(slide.originalUrl, slide.title);
  }, [lightboxIndex, slides]);

  const onUpdates = useMemo(() => {
    return {
      view: (current) => setLightboxIndex(current.index)
    }
  }, []);

  return (
    <>
      <Grid
        className={classes.root}
        item container spacing={(images.length > 0 || documents.length > 0) ? 1 : undefined} ref={ref}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        onDragOver={onDragOver}
      >

        {
          isEmpty ? (
            <EmptyState
              className={classes.emptyContainer}
              title={t("mission:cards:files:emptyStateTitle")}
              description={slim ? t("mission:cards:files:emptyStateDescriptionSlim") : t("mission:cards:files:emptyStateDescription")}
            >
              <input
                className={classes.clickableFileUpload}
                type="file"
                accept="*"
                onChange={onSelectFiles}
                disabled={locked}
                multiple
              />

            </EmptyState>
          ) : null
        }

        {
          dragOver > 0 && !locked ? (
            <DropTarget
              title={t("mission:cards:files:dropTargetTitle")}
              description={t("mission:cards:files:dropTargetDescription")}
            />
          ) : null
        }

        {
          images.length > 0 ? (
            <>
              {!slim ? (
                <Grid item xs={12}>
                  <Typography variant="subtitle1">{t("mission:cards:files:imagesHeader")}</Typography>
                </Grid>
              ) : null}

              {images.map((f, key) =>
                <ImageItem
                  key={key}
                  file={f}
                  onClick={() => (setLightboxIndex(key), setLightboxOpen(true))}
                  onDelete={ev => onDeleteFile(ev, f.id)} sx={slim ? 6 : 3}
                />
              )}
            </>
          ) : null
        }

        {
          documents.length > 0 ? (
            <>
              <Grid item xs={12}>
                <Typography variant="subtitle1">{t("mission:cards:files:documentsHeader")}</Typography>
                <List>
                  {documents.map((f, key) =>
                    <Fragment key={key}>
                      <DocumentItem file={f} onDelete={ev => onDeleteFile(ev, f.id)} />
                      {key !== documents.length - 1 ? (
                        <Divider />
                      ) : null}
                    </Fragment>
                  )}
                </List>
              </Grid>
            </>
          ) : null
        }

      </Grid>

      <ConfirmDialog
        task={deleteAction}
        title={t("mission:cards:files:onDeleteTitle")}
        description={t("mission:cards:files:onDeleteDescription")}
        confirmText={t("common:delete")}
      />

      <Lightbox
        styles={LIGHTBOX_STYLES}
        plugins={PLUGINS}
        index={lightboxIndex}
        slides={slides}
        open={lightboxOpen}
        close={() => setLightboxOpen(false)}
        on={onUpdates}
        animation={ANIMATION}
        toolbar={{
          buttons: [
            "download",
            <IconButton title="Download original" onClick={onDownloadOriginal} className="yarl__button" key="dl-orig">
              <DownloadIcon />
            </IconButton>,
            "share",
            "zoom",
            "close",
          ],
        }}
      />

    </>
  );
}

function ImageItem(props) {
  const { file, onDelete, sx, onClick } = props;
  const { classes } = useStyles();

  const loading = !file.complete;
  const progress = loading && !!file.loaded ? (file.loaded / file.total) * 100.0 : 0;
  const deleting = file.deleting;

  const imageUrl = useMemo(() => {
    return !!file.url ? `${file.url.replace(`https://storage.googleapis.com/assist-private-${config.isProd ? "prod" : "dev"}`, "https://ik.imagekit.io/assist/")}?tr=h-200` : null;
  }, [file]);

  return !!file ? (
    <Grid item container xs={sx}>
      <Grid item className={classes.imageContainer}>
        {
          !!imageUrl && file.complete && !deleting ? (
            <img src={imageUrl} className={`${classes.image} gallery-image`} alt={file.name} loading="lazy" onClick={onClick} />
          ) : (
            <div className={classes.imageProgressContainer}>
              <CircularProgress variant={progress > 0 ? "determinate" : "indeterminate"} value={progress > 0 ? progress : undefined} />
            </div>
          )
        }
        <IconButton size="small" className={classes.deleteImageButton} onClick={onDelete} disabled={deleting || (loading && !!file.total)}>
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  ) : null;
}

function DocumentItem(props) {
  const { file, onDelete } = props;
  const { classes } = useStyles();

  const loading = !file.complete;
  const progress = loading && !!file.loaded ? (file.loaded / file.total) * 100.0 : 0;
  const deleting = file.deleting;

  return !!file ? (
    <ListItem
      className={classes.documentContainer}
      onClick={() => {
        if (file.complete) {
          window.open(file.url, "_blank").focus();
        }
      }}
      secondaryAction={
        <IconButton edge="end" aria-label="delete" onClick={onDelete} disabled={deleting || (loading && !!file.total)}>
          <DeleteIcon />
        </IconButton>
      }
    >
      <ListItemAvatar>
        {!!file.url && file.complete && !deleting ? (
          <FileIcon fontSize="large" />
        ) : (
          <CircularProgress variant={progress > 0 ? "determinate" : "indeterminate"} value={progress > 0 ? progress : undefined} />
        )}
      </ListItemAvatar>
      <ListItemText primary={file.name} secondary={`${!!file.created ? moment(file.created).format("lll") : ""} - ${formatFileSize(file.size)}`} />
    </ListItem>
  ) : null;
}

const formatFileSize = (size) => {
  return size < 1024 ? `${size}B` : size < 1024 * 1024 ? `${Math.ceil(size / 1024)}KB` : `${Math.ceil(size / (1024 * 1024))}MB`;
}


async function downloadURI(uri, name) {
  try{
    await axios({
      url: uri,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
       const url = window.URL.createObjectURL(new Blob([response.data]));
       const link = document.createElement("a");
       link.href = url;
       link.setAttribute("download", name);
       document.body.appendChild(link);
       link.click();
    });
  } catch(error){
    console.log(error)
  }
}