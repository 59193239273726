import { Box, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { NavLink } from "react-router-dom"
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from "@mui/icons-material";

const useStyles = makeStyles()(
  theme => ({
    listItem: {
      height: "48px",
    },
    category: {
      height: "48px",
      marginBottom: "2px",
      cursor: "pointer",
      transition: "color 0.2s",
      transitionTimingFunction: "linear",
      color: theme.palette.primary.main,
      "& .MuiSvgIcon-root": {
        color: theme.palette.primary.main,
      },
      "&:after": {
        zIndex: 3,
        position: "absolute",
        height: "100%",
        width: "100%",
        right: 0,
        opacity: 0,
        background: theme.palette.action.hover,
        borderRadius: "8px",
        content: "''",
        display: "block"
      },
      "&:hover:after": {
        opacity: 1
      },
      "& .MuiListItemSecondaryAction-root": {
        right: "8px",
        top: "28px"
      }
    },
    categoryActive: {
      "&:after": {
        opacity: 1
      }
    },
    listIcon: {
      minWidth: "36px",
      marginLeft: "-8px"
    },
    sidebarLink: {
      display: "block",
      marginBottom: "2px"
    },
    link: {
      textDecoration: "none",
      color: theme.palette.text.secondary,
      transition: "color 0.2s",
      transitionTimingFunction: "linear",
      "& li": {
        transition: "color 0.2s",
        transitionTimingFunction: "linear",
        color: theme.palette.primary.main,
        "& .MuiSvgIcon-root": {
          color: theme.palette.primary.main,
        },
        "&:before": {
          zIndex: -1,
          position: "absolute",
          height: "100%",
          width: "100%",
          right: 0,
          background: theme.palette.action.hover,
          opacity: 0,
          borderRadius: "8px",
          content: "''",
          display: "block",
          transform: "scale(0,1)",
          transition: "opacity 0.2s, transform 0.2s",
          transitionTimingFunction: "linear"
        },
        "&:after": {
          zIndex: 3,
          position: "absolute",
          height: "100%",
          width: "100%",
          right: 0,
          opacity: 0,
          background: theme.palette.action.hover,
          borderRadius: "8px",
          content: "''",
          display: "block"
        },
      },
      "&.active > li, &.active svg": {
        zIndex: 1,
        "&:before": {
          opacity: 1,
          transform: "scale(1,1)"
        }
      },
      "&:hover li:after": {
        opacity: 1
      }
    },
    text: {
      fontWeight: "500",
      color: "inherit",
      userSelect: "none",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis"
    },
    textListItem: {
      fontWeight: "400",
      color: "inherit",
      userSelect: "none",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis"
    },
    subText: {
      padding: "8px",
      userSelect: "none",
      fontWeight: "600",
      padding: theme.spacing(0.5),
      borderRadius: theme.spacing(0.5),
      background: theme.palette.neutral.main,
      marginRight: "-4px"
    },
  }));

export default function SidebarListItem(props) {
  const { text, to, onClick, subText, compactMode } = props;
  const { classes } = useStyles();

  return (
    <NavLink to={to} className={`${classes.sidebarLink} ${classes.link}`}>
      <ListItem className={classes.listItem} onClick={onClick} secondaryAction={!!subText && !compactMode ? <Typography className={classes.subText} variant="caption" textAlign="right">{subText}</Typography> : undefined}>
        <ListItemText>
          <Typography variant="body2" className={classes.textListItem}>
            {!compactMode ? <>&bull; &nbsp;</> : null} {text}
          </Typography>
        </ListItemText>
      </ListItem>
    </NavLink>
  );
}


export function SidebarLink(props) {
  const { text, to, onClick, subText, icon, compactMode } = props;
  const { classes } = useStyles();

  return (
    <NavLink to={to} className={`${classes.sidebarLink} ${classes.link}`}>
      <ListItem className={classes.listItem} onClick={onClick} secondaryAction={!!subText ? <Typography className={classes.subText} variant="subtitle2" textAlign="right">{subText}</Typography> : undefined}>
        <ListItemIcon className={classes.listIcon}>
          {icon}
        </ListItemIcon>
        {
          !compactMode ? (
            <ListItemText>
              <Typography variant="body2" className={classes.text}>
                {text}
              </Typography>
            </ListItemText>
          ) : null
        }
      </ListItem>
    </NavLink>
  );
}



export function SidebarCategoryItem(props) {
  const { text, onClick, expanded, active, icon, compactMode, forwardRef } = props;
  const { classes } = useStyles();

  return (
    <ListItem ref={forwardRef} className={`${classes.category} ${active && compactMode ? classes.categoryActive : ""}`} onClick={onClick} secondaryAction={compactMode ? undefined : (expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />)}>
      <ListItemIcon className={classes.listIcon}>
        {icon}
      </ListItemIcon>
      {
        !compactMode ? (
          <ListItemText>
            <Typography variant="body2" className={classes.text}>
              {text}
            </Typography>
          </ListItemText>
        ) : null
      }
    </ListItem>
  );
}

export function SidebarButton(props) {
  const { text, onClick, subText, icon, compactMode } = props;
  const { classes } = useStyles();

  return (
    <Box className={classes.link}>
      <ListItem className={classes.listItem} onClick={onClick} secondaryAction={!!subText ? <Typography className={classes.subText} variant="subtitle2" textAlign="right">{subText}</Typography> : undefined}>
        <ListItemIcon className={classes.listIcon}>
          {icon}
        </ListItemIcon>
        {
          !compactMode ? (
            <ListItemText>
              <Typography variant="body2" className={classes.text}>
                {text}
              </Typography>
            </ListItemText>
          ) : null
        }
      </ListItem>
    </Box>
  );
}
