import { Autocomplete, Avatar, ListItem, ListItemAvatar, ListItemText, TextField } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useEntityById, useSearch, Search } from "@emberly/zenith-client";
import { useTranslation } from "react-i18next";
import { useMission } from "../../../providers/MissionProvider";
import { Handshake as HandshakeIcon, Category as CategoryIcon, Numbers as NumbersIcon } from "@mui/icons-material";

export default function AgreementProductPicker(props) { // https://mui.com/material-ui/react-autocomplete/
  const { path, label, value, onChange, disabled } = props;
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState(null);
  const { entities } = useSearch("Product", searchQuery);
  const { mission } = useMission();

  const agreement = mission?.actors?.contract?.agreement;

  const { entity: agreementEntity } = useEntityById("Agreement", agreement?.id, !!agreement?.id);
  const discountedProducts = agreementEntity?.discountedProducts;

  const [option, setOption] = useState(value || null);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setOption(value);
  }, [value]);

  // Make input, with button, and allow for endAdornment prop to be used as drag handle etc

  useEffect(() => {

    const timer = setTimeout(
      () => setSearchQuery({
        should: [
          {
            path: "name",
            function: Search.WILDCARD,
            value: inputValue + "*"
          },
          {
            path: "code",
            function: Search.WILDCARD,
            value: inputValue + "*"
          }
        ]
      }),
      300
    );

    return () => {
      clearTimeout(timer);
    }
  }, [inputValue, path]);

  const onOptionSelected = useCallback((ev, option) => {
    if (!!option) {
      setOption(option);
      onChange(option);
    } else {
      onChange(null);
    }
  }, [onChange]);

  const filteredEntities = useMemo(() => {
    // TODO replace found entities by its discounted counterparts
    const map = new Map();
    discountedProducts?.forEach(p => map.set(p.id, p));
    return entities
      .map(p => map.has(p.id) ? ({ ...p, discounted: true, agreement, discount: map.get(p.id).price, codeMatch: p.code === inputValue }) : ({ ...p, codeMatch: p.code === inputValue }))
      .sort((a, b) => ((b.discounted ? 1 : 0) - (a.discounted ? 1 : 0)) * 1000.0 + (!inputValue ? a.name.localeCompare(b.name) : 0.0))
  }, [discountedProducts, inputValue, entities, agreement]);

  return (
    <Autocomplete
      getOptionLabel={(option) => typeof option === 'string' ? option : option.name}
      isOptionEqualToValue={(a, b) => a.id === b.id}
      options={filteredEntities}
      autoComplete
      fullWidth
      filterOptions={(x) => x}
      includeInputInList
      value={option}
      disabled={disabled}
      noOptionsText={t("common:noResults")}
      onChange={onOptionSelected}
      onInputChange={(_, v) => setInputValue(v)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          size="small"
          variant="filled"
          fullWidth
          sx={{
            "&:hover": {
              backgroundColor: "transparent"
            }
          }}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
          }}
        />
      )}
      renderOption={(props, option) => {
        return (
          <ListItem {...props} key={option.id}>
            <ListItemAvatar>
              <Avatar>
                {option.codeMatch ? <NumbersIcon /> : option.discounted ? <HandshakeIcon /> : <CategoryIcon />}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={option[path]}
              secondary={`#${option.code}${option.discounted ? ` - ${option.agreement.name}` : ""}`}
            />
          </ListItem>
        );
      }}
    />
  );
}


