import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useMemo } from "react";
import { FixNumber } from "../../../../common/orders";
import { getCurrencyShorthand } from "../../../../providers/StationProvider";
import { OrderEnumsLists } from "../../../../common/constants";
import EntityTable from "../../../common/tables/EntityTable";


export default function AllOrdersSection() {
  const { t } = useTranslation();
  const { tabId } = useParams();
  const navigate = useNavigate();

  const columns = useMemo(() => {
    return [
      {
        field: "number",
        type: "number",
        headerName: t("entity:order:number"),
        flex: 0
      },
      {
        field: "missionNumber",
        type: "number",
        headerName: t("common:tables:mission"),
        flex: 0
      },
      {
        field: "customer.name",
        headerName: t("entity:order:customer"),
        valueGetter: (params) => params.row.customer?.name || "-",
        minWidth: 160,
        flex: 0.5,
      },
      {
        field: "customer.organizationNumber",
        headerName: t("entity:contact:organizationNumber"),
        valueGetter: (params) => params.row.customer?.organizationNumber || "-",
        minWidth: 160,
        flex: 0.5,
      },
      {
        field: "mission.target.carDetails.registration||mission.target.boatDetails.registration",
        headerName: t("entity:mission:target:registration"),
        valueGetter: params => {
          const type = params.row.mission.target.type;
          const target = type === 1 ? params.row.mission.target?.carDetails : (type === 2 ? params.row.mission.target.boatDetails : null);
          return target?.registration || "-";
        },
        minWidth: 100,
        flex: 0.5
      },
      {
        field: "mission.actors.requester.name||mission.actors.owner.name",
        headerName: t("common:tables:requester"),
        valueGetter: (params) => params.row.mission.actors.ownerIsNotRequester ? params.row.mission.actors.requester.name : params.row.mission.actors.owner.name,
        flex: 1,
        minWidth: 120,
      },
      {
        field: "payment.method",
        type: "singleSelect",
        minWidth: 160,
        headerName: t("entity:order:payment:method"),
        valueGetter: (params) => params.row.payment.method,
        valueFormatter: (params) => t(`order:enums:paymentMethod:${OrderEnumsLists.PaymentMethod[params.value]}`),
        valueOptions: OrderEnumsLists.PaymentMethod.map((_,i) => ({
          value: i,
          label: t(`order:enums:paymentMethod:${OrderEnumsLists.PaymentMethod[i]}`),
          type: "number"
        })),
      },
      {
        field: "payment.calculatedTotal.value",
        type: "number",
        entityType: "decimal",
        headerName: t("entity:order:payment:calculatedTotal"),
        valueGetter: (params) => Number(params.row.payment.calculatedTotal?.value || "0"),
        valueFormatter: (params) => FixNumber(params.value),
        renderCell: (params) => `${FixNumber(params.row.payment.calculatedTotal?.value)} ${getCurrencyShorthand(t, params.row.payment.calculatedTotal?.currency)}`,
        minWidth: 120
      },
      {
        field: "deductible.price.value",
        type: "number",
        entityType: "decimal",
        headerName: t("entity:order:deductible"),
        valueGetter: (params) => Number(params.row.deductible?.price?.value || "0"),
        valueFormatter: (params) => FixNumber(params.value),
        renderCell: (params) => params.row.deductible ? `${FixNumber(params.row.deductible?.price?.value)} ${getCurrencyShorthand(t, params.row.deductible?.price?.currency)}` : "-",
        minWidth: 120
      },
      {
        field: "payment.sender.name",
        headerName: t("entity:order:sender"),
        valueGetter: (params) => params.row.payment.sender?.name || "-",
        minWidth: 160,
        flex: 0.5
      },
      {
        field: "mission.actors.contract.agreement.name",
        headerName: t("entity:agreement:type"),
        valueGetter: (params) => params.row.mission?.actors?.contract?.agreement?.name || "-",
        minWidth: 160,
        flex: 0.5
      },
      {
        field: "comment",
        headerName: t("entity:order:internalComment"),
        valueGetter: (params) => params.row.comment || "-",
        minWidth: 160,
        flex: 0.5
      },
    ]
  }, [t]);

  const onRowClick = useCallback((params, ev) => {
    navigate(!tabId ? `./all/${params.row.missionId}/${params.id}` : `./${params.row.missionId}/${params.id}`, { relative: "path" });
  }, [navigate, tabId]);


  return (
    <EntityTable
      tableId="all_orders"
      type="OrderMissionView"
      onRowClick={onRowClick}
      columns={columns}
      emptyStateTitle={t("allOrders:emptyStateTitle")}
      emptyStateDescription={t("allOrders:emptyStateDescription")}
      serverSidePaging
      xs={12}
    />
  );
}

