import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useMemo } from "react";
import { Compare } from "@emberly/zenith-client";
import { FixNumber } from "../../../../common/orders";
import { getCurrencyShorthand } from "../../../../providers/StationProvider";
import EntityViewTable from "../../../common/tables/EntityViewTable";
import { FullTimeView } from "../../../common/core/Time";
import { OrderEnumsLists } from "../../../../common/constants";

const Query = {
  name: "unsettled_orders",
  and: [
    {
      path: "payment.terminal.settled",
      comparer: Compare.EQ,
      value: false
    },
    {
      path: "payment.terminal.confirmed",
      value: true,
      comparer: Compare.EQ,
    }
  ]
};

export default function UnsettledOrdersSection() {
  const { t } = useTranslation();
  const { tabId } = useParams();
  const navigate = useNavigate();

  const columns = useMemo(() => {
    return [
      {
        field: "payment.sender.name",
        headerName: t("entity:order:sender"),
        valueGetter: (params) => params.row.payment.sender?.name || "-",
        minWidth: 160,
        flex: 0.35,
      },
      {
        field: "payment.terminal.confirmedDate",
        headerName: t("common:tables:paymentConfirmed"),
        type: "dateTime",
        valueGetter: params => params.row.payment.terminal?.confirmedDate,
        renderCell: (params) => <FullTimeView time={params.value} />,
        flex: 0.25,
        minWidth: 160,
      },
      {
        field: "payment.method",
        headerName: t("entity:order:payment:method"),
        valueGetter: (params) => t(`order:enums:paymentMethod:${OrderEnumsLists.PaymentMethod[params.row.payment.method]}`),
        minWidth: 160
      },
      {
        field: "payment.calculatedTotal.value",
        type: "number",
        entityType: "decimal",
        headerName: t("entity:order:payment:calculatedTotal"),
        valueGetter: (params) => Number(params.row.payment.calculatedTotal?.value || "0"),
        valueFormatter: (params) => FixNumber(params.value),
        renderCell: (params) => `${FixNumber(params.row.payment.calculatedTotal?.value)} ${getCurrencyShorthand(t, params.row.payment.calculatedTotal?.currency)}`,
        minWidth: 120
      },
      {
        field: "number",
        type: "number",
        headerName: t("entity:order:number"),
        flex: 0
      },
      {
        field: "missionNumber",
        type: "number",
        headerName: t("common:tables:mission"),
        flex: 0
      },
      {
        field: "customer.name",
        headerName: t("entity:order:customer"),
        valueGetter: (params) => params.row.customer?.name || "-",
        minWidth: 160,
        flex: 0.35,
      },
      {
        field: "comment",
        headerName: t("entity:order:internalComment"),
        valueGetter: (params) => params.row.comment || "-",
        minWidth: 160,
        flex: 0.75
      },
    ]
  }, [t]);

  const onRowClick = useCallback((params, ev) => {
    navigate(!tabId ? `./unsettled/${params.row.missionId}/${params.id}` : `./${params.row.missionId}/${params.id}`, { relative: "path" });
  }, [navigate, tabId]);

  return (
    <EntityViewTable
      tableId="unsettled_orders"
      type="OrderMissionView"
      filter={Query}
      onRowClick={onRowClick}
      columns={columns}
      emptyStateTitle={t("unsettledCashOrders:emptyStateTitle")}
      emptyStateDescription={t("unsettledCashOrders:emptyStateDescription")}
      serverSidePaging
      xs={12}
    />
  );
}

