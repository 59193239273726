import { makeStyles } from "tss-react/mui";
import { Grid, Typography, Collapse } from "@mui/material";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom"
import { Link as LinkIcon, InfoOutlined as InfoIcon } from "@mui/icons-material"


const useStyles = makeStyles()(
  (theme) => ({
    root: {
      marginTop: theme.spacing(0.5),
    },
    link: {
      color: theme.palette.text.secondary,
    },
    icon: {
      marginRight: theme.spacing(0.5),
      fontSize: "20px"
    },
    description: {
      color: theme.palette.info.main,
      marginTop: theme.spacing(0.5)
    }
  })
);

export default function AgreementLink(props) {
  const { agreementNumber, agreementId, open, description } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <Collapse in={!!open}>
      <Grid item xs={12} className={classes.root}>

        <NavLink to={`/agreements/${agreementId}`} className={classes.link}>
          <Grid container>
            <LinkIcon className={classes.icon} />
            <Typography variant="body2">{t("entity:agreement:type")}: {agreementNumber}</Typography>
          </Grid>
        </NavLink>

        {
          !!description ? (
            <Grid container className={classes.description}>
              <InfoIcon className={classes.icon} />
              <Typography variant="body2">{description}</Typography>
            </Grid>
          ) : null
        }

      </Grid>
    </Collapse>
  );
}



