import { jsPDF } from "jspdf";
import { MissionEnums, MissionEnumsLists, OrderEnumsLists } from "./constants";
import moment from "moment/moment";
import { CalcProductionSum, CalcProductionTotal, CalcProductionVat, CalcToPaySum, FixNumber, CalcTotal, GetPaymentDate } from "./orders";

const CellWidth = 50;
const Padding = 4;

const FontSize = 11;
const FontSizeTable = 12;
const HeaderSize = 24;

const RowSize = 8;
const RowSizeD = 6;

export function MakeReceipt(station, mission, orders, order, priceUnit, t) {
  const doc = new jsPDF({ format: "dl" });

  const targetType = mission.target.type;
  const targetDetails = targetType === 1 ? mission.target.carDetails : (targetType === 2 ? mission.target.boatDetails : null);
  const currency = t(`order:enums:currencyShortHand:${OrderEnumsLists.Currency[priceUnit]}`); // TODO

  const hasDeductible = !!order.deductible;
  const hasVatTransferTo = !!order.vatTransfer;

  const transferDeductibleEntity = orders.find(t => t.deductible?.order?.id === order.id);
  const hasTransferDeductible = !!transferDeductibleEntity;

  const transferVatEntity = orders.find(t => t.vatTransfer?.order?.id === order.id);
  const hasVatTransferFrom = !!transferVatEntity;

  const vatSum = FixNumber(CalcProductionVat(order.orderLines)); // TODO

  let offsetY = Padding * 3;

  doc.setFontSize(HeaderSize);
  doc.setFont("helvetica", "bold");
  doc.text(t("order:receipt:title"), Padding, offsetY);
  doc.setFont("helvetica", "normal");
  offsetY = OffsetY(doc, offsetY, Padding);

  doc.setFontSize(FontSize);


  const info = [
    {
      name: t("order:receipt:seller"),
      value: station?.legalName || "-"
    },
    {
      name: t("entity:order:number"),
      value: `${order.number}`
    },
    {
      name: t("order:receipt:missionNumber"),
      value: `${mission.number}`
    },
    {
      name: t("order:receipt:missionType"),
      value: `${t(`mission:enums:type:${MissionEnumsLists.Type[mission.details.type]}`)}`
    },
    (mission.details.type === MissionEnums.Type.Salvage ? (
      {
        name: t("order:receipt:cause"),
        value: mission.details.cause?.name || "-"
      }
    ) : null),
    (!!targetDetails && !!targetDetails.make ? (
      {
        name: t(`mission:enums:targetType:${MissionEnumsLists.TargetType[mission.target.type]}`),
        value: `${targetDetails?.make || ""} ${targetDetails?.model || ""}`
      }
    ) : null),
    {
      name: t("entity:mission:target:registration"),
      value: targetDetails?.registration || "-"
    },
    {
      name: t("entity:order:payment:method"),
      value: t(`order:enums:paymentMethod:${OrderEnumsLists.PaymentMethod[order.payment.method || 0]}`)
    },
    {
      name: t("order:receipt:person"),
      value: order.payment?.sender?.name || "-"
    },
    {
      name: t("common:comment"),
      value: order.payment?.comment || "-"
    },
    {
      name: t("order:receipt:agreementReference"),
      value: mission?.actors?.contract?.reference || "-"
    },
    {
      name: t("order:receipt:date"),
      value: moment(GetPaymentDate(order.payment)).format("lll")
    },
    {
      name: t("order:receipt:created"),
      value: moment(mission.created).format("lll")
    },
  ].filter(t => !!t);

  for (let i = 0; i < info.length; i++) {
    const row = info[i];

    offsetY = OffsetY(doc, offsetY, RowSize * 0.75);

    doc.setFont("helvetica", "bold");
    doc.text(row.name, Padding, offsetY);
    doc.setFont("helvetica", "normal");

    doc.text(row.value, CellWidth, offsetY);
  }


  offsetY = OffsetY(doc, offsetY, Padding * 2);
  divider(doc, offsetY);
  offsetY = OffsetY(doc, offsetY, RowSizeD);

  // Render OrderLines headers

  doc.setFont("courier", "normal");
  doc.setFontSize(FontSizeTable);

  doc.text(t("order:receipt:productTitle"), Padding, offsetY);
  doc.text(t("order:receipt:sumTitle"), CellWidth * 2, offsetY, null, null, "right");

  offsetY = OffsetY(doc, offsetY, Padding);

  divider(doc, offsetY);


  // Render OrderLines
  for (let i = 0; i < order.orderLines.length; i++) {
    const orderLine = order.orderLines[i];
    const currency = t(`order:enums:currencyShortHand:${OrderEnumsLists.Currency[orderLine.price.currency]}`);
    const discounted = !!orderLine.discount && orderLine.discount !== "0";

    offsetY = OffsetY(doc, offsetY, RowSize);

    doc.setFont("courier", "bold");
    doc.text(orderLine.name, Padding, offsetY);
    doc.text(`${FixNumber(CalcTotal(orderLine.price, orderLine.quantity, orderLine.discount))} ${currency}`, CellWidth * 2, offsetY, null, null, "right");
    doc.setFont("courier", "normal");

    offsetY = OffsetY(doc, offsetY, Padding);

    doc.text(`${FixNumber(orderLine.quantity)} ${t("order:orderLineItem:unit")} ${t("order:orderLineItem:at")} ${FixNumber(CalcTotal(orderLine.price, "1", orderLine.discount))} ${currency}`, Padding, offsetY);
    doc.text(`${orderLine.productGroup.vatCode.rate}% ${t("order:orderLineItem:vat")}`, CellWidth * 2, offsetY, null, null, "right");

    if (discounted) {
      offsetY = OffsetY(doc, offsetY, Padding);
      doc.text(`${orderLine.discount}% ${t("order:orderLineItem:discount")}`, Padding, offsetY);
    }
  }

  offsetY = OffsetY(doc, offsetY, Padding);
  divider(doc, offsetY);
  offsetY = OffsetY(doc, offsetY, RowSizeD);


  // Render summary 


  doc.setFont("courier", "bold");
  row(doc, offsetY, t("order:summary:production"), `${FixNumber(CalcProductionSum(order.orderLines))} ${currency}`);
  doc.setFont("courier", "normal");
  offsetY = OffsetY(doc, offsetY, RowSize);

  row(doc, offsetY, t("order:summary:vat"), `${vatSum} ${currency}`);
  offsetY = OffsetY(doc, offsetY, RowSize - Padding);

  divider(doc, offsetY);
  offsetY = OffsetY(doc, offsetY, RowSizeD);

  doc.setFont("courier", "bold");
  row(doc, offsetY, t("order:summary:sumProduction"), `${FixNumber(CalcProductionTotal(order.orderLines))} ${currency}`);
  doc.setFont("courier", "normal");
  offsetY = OffsetY(doc, offsetY, RowSize);

  if (hasDeductible) {
    row(doc, offsetY, t("entity:order:deductible"), `${FixNumber(order.deductible.price.value || "0")} ${t(`order:enums:currencyShortHand:${OrderEnumsLists.Currency[order.deductible.price.currency]}`)}`);
    offsetY = OffsetY(doc, offsetY, RowSize);
  }

  if (hasTransferDeductible) {
    row(doc, offsetY, t("entity:order:deductible"), `-${FixNumber(transferDeductibleEntity.deductible.price.value || "0")} ${t(`order:enums:currencyShortHand:${OrderEnumsLists.Currency[transferDeductibleEntity.deductible.price.currency]}`)}`);
    offsetY = OffsetY(doc, offsetY, RowSize);
  }

  if (hasVatTransferTo) {
    row(doc, offsetY, `${t("order:summary:vatTransfer")}`, `-${vatSum} ${t(`order:enums:currencyShortHand:${OrderEnumsLists.Currency[priceUnit]}`)}`);
    offsetY = OffsetY(doc, offsetY, RowSize);
  }

  if (hasVatTransferFrom) {
    row(doc, offsetY, `${t("order:summary:vatTransfer")}`, `${CalcProductionVat(transferVatEntity.orderLines).toFixed(2)} ${t(`order:enums:currencyShortHand:${OrderEnumsLists.Currency[priceUnit]}`)}`);
    offsetY = OffsetY(doc, offsetY, RowSize);
  }


  offsetY = OffsetY(doc, offsetY, -Padding);
  divider(doc, offsetY);
  offsetY = OffsetY(doc, offsetY, RowSizeD);

  doc.setFont("courier", "bold");
  row(doc, offsetY, t("order:summary:sumToPay"), `${FixNumber(CalcToPaySum(order, orders))} ${currency}`);
  doc.setFont("courier", "normal");

  return doc;
}


function divider(doc, y) {
  doc.line(Padding, y, CellWidth * 2 + Padding, y);
}


function row(doc, y, name, value) {
  doc.text(name, Padding, y);
  doc.text(value, CellWidth * 2, y, null, null, "right");
}

function OffsetY(doc, offsetY, v = 0) {
  const pageHeight = doc.internal.pageSize.height;

  if (offsetY + v >= pageHeight) {
    doc.addPage();
    return Padding * 2;
  } else {
    return offsetY + v;
  }
}