import { IconButton, Tooltip } from "@mui/material";
import moment from "moment/moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ContentCopy as CopyIcon } from "@mui/icons-material";
import { copyToClipboard } from "../../../common/helpers";
import { useNotification } from "../../../providers/NotificationProvider";

// Full view

export function TimeView(props) {
  const { time, refresh } = props;
  const { t } = useTranslation();
  
  const { displayTime, tooltipTime } = useMemo(() => {
    const m = moment(time);
    return {
      displayTime: !time ? "-" : getDisplayTime(t, m),
      tooltipTime: !time ? "-" : m.format("LLLL")
    }
  }, [time, t, refresh]);

  return (
    <TooltipWithCopy title={tooltipTime} t={t}>
      <span>{displayTime}</span>
    </TooltipWithCopy>
  );
}

// Compact View

export function CompactTimeView(props) {
  const { time, refresh } = props;
  const { t } = useTranslation();

  const { displayTime, tooltipTime } = useMemo(() => {
    const m = moment(time);
    return {
      displayTime: !time ? "-" : getCompactDisplayTime(t, m),
      tooltipTime: !time ? "-" : m.format("LLLL")
    }
  }, [time, t, refresh]);

  return (
    <TooltipWithCopy title={tooltipTime} t={t} >
      <span>{displayTime}</span>
    </TooltipWithCopy>
  );
}

export function FullTimeView(props) {
  const { time, isDate } = props;
  
  const { t } = useTranslation();

  const { displayTime, tooltipTime } = useMemo(() => {
    const m = moment(time);
    return {
      displayTime: !time ? "-" : m.format(isDate ? "L" : `L LT`),
      tooltipTime: !time ? "-" : m.format("LLLL")
    };
  }, [time, isDate, t]);

  return (
    <TooltipWithCopy title={tooltipTime} t={t} >
      <span>{displayTime}</span>
    </TooltipWithCopy>
  );
}

const IconButtonStyle = { color: "#fff", padding: "4px", marginTop: "-4px" };
const IconStyle = { fontSize: "16px" }


function TooltipWithCopy(props) {
  const { snackbar } = useNotification();

  return (
    <Tooltip
      title={
        <>
          {props.title}
          {
            !!navigator.clipboard ?
              <IconButton onClick={() => (copyToClipboard(props.title), snackbar(props.t("common:copiedToClipboard")))} size="inherit" color="#fff" style={IconButtonStyle}><CopyIcon size="inherit" style={IconStyle} /></IconButton>
              : null
          }
        </>
      }
      enterDelay={750}
    >
      {props.children}
    </Tooltip>
  );
}


function getDisplayTime(t, m) {
  const diff = moment().diff(m);
  const isFuture = diff < 0;
  const seconds = Math.abs(diff) / 1000;
  const minutes = seconds / 60;
  const hours = minutes / 60;
  const days = hours / 24;

  if (seconds < 10) {
    return t("common:justNow");
  } else if (minutes <= 1) {
    return t(isFuture ? "common:inOneMinute" : "common:justThisMinute");
  } else if (hours < 1) {
    return m.fromNow();
  } else if (days < 1 && isToday(m)) {
    return `${t("common:today")} ${m.format("LT")} (${m.fromNow()})`;
  } else {
    return m.format(`L LT`);
  }
}


function getCompactDisplayTime(t, m) {
  const diff = moment().diff(m);
  const seconds = Math.abs(diff) / 1000;
  const minutes = seconds / 60;
  const hours = minutes / 60;
  const days = hours / 24;

  if (seconds < 10) {
    return t("common:now");
  } else if (minutes <= 1) {
    return "1m";
  } else if (hours < 1) {
    return m.fromNow(true);
  } else if (days < 1 && isToday(m)) {
    return `${t("common:today")} ${m.format("LT")}`;
  } else {
    return m.format("Do MMM");
  }
}


function isToday(m) {
  return m.isSame(new Date(), "day");
}



let testRun = false;

function test(t) {
  if (testRun) {
    return;
  }
  testRun = true;

  const timesPast = [
    moment().subtract(1, "seconds"),
    moment().subtract(30, "seconds"),
    moment().subtract(30, "minutes"),
    moment().subtract(7, "hours"),
    moment().subtract(2, "days"),
    moment().subtract(10, "days"),
  ];

  const timesFuture = [
    moment().add(1, "seconds"),
    moment().add(30, "seconds"),
    moment().add(30, "minutes"),
    moment().add(3, "hours"),
    moment().add(2, "days"),
    moment().add(10, "days"),
  ];

  let res = [];

  res.push("### Past");
  for (let i = 0; i < timesPast.length; i++) {
    const time = timesPast[i];
    res.push(time.fromNow(true));
    res.push(`- Comp: ${getCompactDisplayTime(t, time)}`);
    res.push(`- Long: ${getDisplayTime(t, time)}`);
  }

  res.push("\n\n\n### Future");
  for (let i = 0; i < timesFuture.length; i++) {
    const time = timesFuture[i];
    res.push(time.fromNow())
    res.push(`- Comp: ${getCompactDisplayTime(t, time)}`);
    res.push(`- Long: ${getDisplayTime(t, time)}`);
  }

  console.log(res.join("\n"));
}
