import { Grid, Button, MenuItem, FormControl, Dialog, DialogTitle, DialogContent, DialogActions, InputLabel, Select, TextField, Box, Chip, FormHelperText } from "@mui/material";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUserManagement } from "../../../../providers/UserManagementProvider";
import { makeStyles } from "tss-react/mui";
import { validateEmail } from "../../../../common/helpers";
import { useAuth } from "@emberly/zenith-client";

const useStyles = makeStyles()(
  (theme) => ({
    selected: {
      fontWeight: 500
    }
  })
);

export default function UserInviteDialog(props) {
  const { task } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { user } = useAuth();

  const [email, setEmail] = useState("");
  const [userRoles, setUserRoles] = useState([]);
  const { roles, users, invites } = useUserManagement();
  const [emailBlurred, setEmailBlurred] = useState(false);
  const { emailError, emailExists, validEmail } = useMemo(() => {
    const validEmail = validateEmail(email);

    return {
      validEmail,
      emailError: !!email && !validEmail && emailBlurred,
      emailExists: !!email && validEmail && !!(users.find(u => u.email === email) || invites.find(inv => inv.email === email)),
    }
  }, [email, users, invites, emailBlurred]);

  const isValid = userRoles.length !== 0 && validEmail && !emailExists;

  return (
    <Dialog
      open
      onClose={() => task.throw("cancel")}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        {t("userManagement:inviteDialog:title")}
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={1}>

          <Grid item xs={12}>
            <TextField
              onBlur={() => setEmailBlurred(true)}
              required
              error={emailExists || emailError}
              helperText={emailExists ? t("userManagement:inviteDialog:alreadyExists") : emailError ? t("common:invalidEmail") : undefined}
              label={t("common:email")}
              onChange={ev => setEmail(ev.target.value)}
              value={email}
              variant="filled"
              fullWidth
              size="small"
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl variant="filled" fullWidth required>
              <InputLabel id="chip-label">{t("userManagement:inviteDialog:roles")}</InputLabel>
              <Select
                labelId="chip-label"
                required
                multiple
                value={userRoles}
                onChange={(ev) => setUserRoles(ev.target.value)}
                renderValue={(roleIds) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {roleIds.map((value) => (
                      <Chip key={value} label={t(`common:roles:${roles.find(r => r.id === value)?.name}`)} />
                    ))}
                  </Box>
                )}
              >
                {roles.map((role, key) => (
                  <MenuItem
                    key={key}
                    value={role.id}
                    className={userRoles.includes(role.id) ? classes.selected : undefined}
                  >
                    {t(`common:roles:${role.name}`)}
                  </MenuItem>
                ))}
              </Select>
              {
                userRoles.length === 0 ? (
                  <FormHelperText>{t("userManagement:inviteDialog:noRoles")}</FormHelperText>
                ) : null
              }
            </FormControl>

          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => task.throw("cancel")}>
          {t("common:cancel")}
        </Button>
        <Button
          variant="contained" color="primary"
          onClick={() => task.complete({
            inviterName: user.name,
            inviteeEmail: email,
            roles: userRoles
          })}
          disabled={!isValid}
        >
          {t("userManagement:inviteDialog:confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
