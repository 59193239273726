import { makeStyles } from "tss-react/mui";
import { Grid, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDevice } from "../../../../providers/DeviceProvider";
import { ToggleSurface } from "../../../common/core/Surface";
import { useNavigate, useParams } from "react-router-dom";
import { useEntities, useMutateState, Compare, Task, useAuth } from "@emberly/zenith-client";
import { useCallback, useMemo, useState } from "react";
import SimpleTable from "../../../common/tables/SimpleTable";
import moment from "moment/moment";
import { FixNumber, sanitizeNumber } from "../../../../common/orders";
import { getCurrencyShorthand, useStation } from "../../../../providers/StationProvider";
import Decimal from "decimal.js";
import ConfirmDialog from "../../../common/inputs/ConfirmDialog";
import { ActivityEnums } from "../../../../common/constants";
import { MakeActivity } from "../../../../common/activity";
import { FullTimeView } from "../../../common/core/Time";

const Query = { name: "pending_settlements", path: "approved", comparer: Compare.EQ, value: false };

const useStyles = makeStyles()(
  (theme) => ({
    approveAllSurface: {
      border: `1px solid ${theme.palette.divider}`
    },
  })
);

export default function CashSettlementsForApprovalSection() {
  const { t } = useTranslation();
  const { slim } = useDevice();
  const { tabId } = useParams();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { user } = useAuth();
  const { priceUnit, logEvent } = useStation();
  const mutateState = useMutateState();

  const [working, setWorking] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);

  const { entities: settlements, updateEntity, loading } = useEntities("CashSettlement", Query);

  const showLoading = loading && settlements.length === 0 || working;

  const onApprove = useCallback(async (settlementId) => {
    await updateEntity({
      id: settlementId,
      approved: true,
      approvedDate: moment.utc().toISOString(),
      approvedBy: { id: user.sub, name: user.name }
    }, ["approved", "approvedDate", "approvedBy"]);
    
    logEvent(MakeActivity(ActivityEnums.Category.Billing, ActivityEnums.Type.SettlementApproved, settlementId));
  }, [user, updateEntity, logEvent]);

  const onApproveAll = useCallback(async () => {
    try {
      setWorking(true);

      const t = new Task();
      setConfirmAction(t);
      await t.wait();

      await mutateState([{
        type: "CashSettlement",
        update: settlements.map(settlement => ({
          data: {
            id: settlement.id,
            approved: true,
            approvedDate: moment.utc().toISOString(),
            approvedBy: { id: user.sub, name: user.name }
          },
          fields: ["approved", "approvedDate", "approvedBy"]
        }))
      }]);

      logEvent(MakeActivity(ActivityEnums.Category.Billing, ActivityEnums.Type.SettlementsApproved, null));

    } catch (err) {
      console.log(err);
    } finally {
      setConfirmAction(null);
      setWorking(false);
    }

  }, [mutateState, settlements, user, logEvent]);

  const columns = useMemo(() => {
    return [
      {
        field: "number",
        type: "number",
        headerName: t("cashSettlementsForApproval:fieldNumber"),
        flex: 0,
      },
      {
        field: "created",
        type: "date",
        headerName: t("cashSettlementsForApproval:fieldSettledDate"),
        valueGetter: (params) => params.row.created,
        renderCell: (params) => <FullTimeView time={params.value} />,
        minWidth: 120,
        flex: 0.5,
      },
      {
        field: "sender.name",
        headerName: t("cashSettlementsForApproval:fieldSender"),
        valueGetter: (params) => params.row.sender.name || "-",
        minWidth: 200,
        flex: 0.5,
      },
      {
        field: "sumCash",
        type: "number",
        headerName: t("cashSettlementsForApproval:fieldSumCash"),
        valueGetter: (params) => Number(params.row.cashSum.value || "0"),
        valueFormatter: (params) => FixNumber(params.value),
        renderCell: (params) => `${FixNumber(params.row.cashSum.value)} ${getCurrencyShorthand(t, params.row.cashSum.currency)}`,
        minWidth: 120,
        flex: 0.5,
      },
      {
        field: "sumCard",
        type: "number",
        headerName: t("cashSettlementsForApproval:fieldSumCard"),
        valueGetter: (params) => Number(params.row.cardSum.value || "0"),
        valueFormatter: (params) => FixNumber(params.value),
        renderCell: (params) => `${FixNumber(params.row.cardSum.value)} ${getCurrencyShorthand(t, params.row.cardSum.currency)}`,
        minWidth: 120,
        flex: 0.5,
      },
      {
        field: "sumApp",
        type: "number",
        headerName: t("cashSettlementsForApproval:fieldSumApp"),
        valueGetter: (params) => Number(params.row.mobilePaymentSum.value || "0"),
        valueFormatter: (params) => FixNumber(params.value),
        renderCell: (params) => `${FixNumber(params.row.mobilePaymentSum.value)} ${getCurrencyShorthand(t, params.row.mobilePaymentSum.currency)}`,
        minWidth: 120,
        flex: 0.5,
      },
      {
        field: "sumTotal",
        type: "number",
        headerName: t("cashSettlementsForApproval:fieldSumTotal"),
        valueGetter: (params) => Number(params.row.totalSum.value || "0"),
        valueFormatter: (params) => FixNumber(params.value),
        renderCell: (params) => `${FixNumber(params.row.totalSum.value)} ${getCurrencyShorthand(t, params.row.totalSum.currency)}`,
        minWidth: 120,
        flex: 0.5,
      },
      {
        field: "approve",
        type: "number",
        headerName: "",
        sortable: false,
        flex: 0.5,
        minWidth: 120,
        renderCell: (params) => {
          const onClick = (ev) => {
            ev.preventDefault();
            ev.stopPropagation();
            onApprove(params.id);
          };

          return (
            <Button variant="contained" color="inherit" size="small" onClick={onClick}>{t("cashSettlementsForApproval:fieldApprove")}</Button>
          );
        }
      },
    ]
  }, [t, onApprove]);

  const onRowClick = useCallback((params, ev) => {
    navigate(!tabId ? `./approval/${params.id}` : `./${params.id}`, { relative: "path" });
  }, [navigate, tabId]);

  const totalSettlementsSum = useMemo(() => {
    if (settlements.length === 0) return "0,00";
    return FixNumber(settlements.map(t => new Decimal(sanitizeNumber(t.totalSum.value))).reduce((a, b) => a.plus(b), new Decimal("0")));
  }, [settlements]);

  return (
    <>
      {
        settlements.length !== 0 ? (
          <ToggleSurface surface xl={4} lg={5} md={6} sm={8} xs={12} className={slim ? classes.approveAllSurface : undefined}>
            <Grid item container xs={12} spacing={1}>

              <Grid item xs={12}>
                <Typography variant="subtitle1">{t("cashSettlementsForApproval:approveAllTitle")}</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body2">
                  {t("cashSettlementsForApproval:approveAllDescriptionPart1")} {settlements.length} {t("cashSettlementsForApproval:approveAllDescriptionPart2")} {totalSettlementsSum} {getCurrencyShorthand(t, priceUnit)} {t("cashSettlementsForApproval:approveAllDescriptionPart3")}
                </Typography>
              </Grid>

              <Grid item xs={12} container justifyContent="flex-end">
                <Button variant="contained" disabled={showLoading || loading} onClick={onApproveAll}>{t("cashSettlementsForApproval:approveAllButton")}</Button>
              </Grid>
            </Grid>
          </ToggleSurface>
        ) : null
      }

      <ToggleSurface>
        <Grid item xs={12}>
          <SimpleTable
            tableId="cashsettlements_for_approval"
            loading={showLoading}
            onRowClick={onRowClick}
            columns={columns}
            rows={settlements}
            emptyStateTitle={t("cashSettlementsForApproval:emptyStateTitle")}
            emptyStateDescription={t("cashSettlementsForApproval:emptyStateDescription")}
          />
        </Grid>
      </ToggleSurface>

      <ConfirmDialog
        task={confirmAction}
        title={t("cashSettlementsForApproval:approveAllDialog:title")}
        description={t("cashSettlementsForApproval:approveAllDialog:description")}
        confirmText={t("cashSettlementsForApproval:approveAllDialog:confirmText")}
      />

    </>
  );
}
