import { Grid, Button, DialogTitle, DialogContent, DialogActions, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEntity } from "@emberly/zenith-client";
import { useState } from "react";
import DateAndTimePicker from "../../inputs/DateAndTimePicker";
import moment from "moment/moment";
import ResponsiveDialog from "../../core/ResponsiveDialog";

export default function InvoiceInformationDialog(props) {
  const { onClose } = props;
  const { t } = useTranslation();
  const { updateEntityField, entity } = useEntity();

  const payment = entity.payment;
  const invoiceInfo = payment?.invoice;

  const [orderDate, setOrderDate] = useState(invoiceInfo?.orderDate || moment.utc().toISOString());
  const [reference, setReference] = useState(payment?.reference || "");
  const [comment, setComment] = useState(payment?.comment || "");

  const onSubmit = () => {
    updateEntityField("payment.invoice.orderDate", orderDate);
    updateEntityField("payment.reference", reference);
    updateEntityField("payment.comment", comment);
    onClose();
  };

  return (
    <ResponsiveDialog
      open
      onClose={onClose}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>
        {t("order:invoiceInformationDialog:title")}
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={1}>

          <Grid item xs={12}>
            <DateAndTimePicker
              label={t("order:invoiceInformationDialog:orderDate")}
              value={orderDate}
              onChange={(ev, v) => setOrderDate(v)}
              fromNow
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label={t("order:invoiceInformationDialog:reference")}
              variant="filled"
              multiline
              fullWidth
              size="small"
              value={reference}
              onChange={ev => setReference(ev.target.value)}
            />
          </Grid>


          <Grid item xs={12}>
            <TextField
              label={t("common:comment")}
              variant="filled"
              multiline
              fullWidth
              size="small"
              value={comment}
              onChange={ev => setComment(ev.target.value)}
            />
          </Grid>


        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t("common:cancel")}
        </Button>
        <Button variant="contained" color="primary" onClick={onSubmit}>
          {t("common:save")}
        </Button>
      </DialogActions>
    </ResponsiveDialog>
  );
}


