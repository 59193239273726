import { makeStyles } from "tss-react/mui";
import { Grid, Chip, Typography, IconButton, Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import { ToggleSurface } from "../../../../common/core/Surface";
import { useTranslation } from "react-i18next";
import { MoreHoriz as MoreIcon } from "@mui/icons-material";
import { EntityContextProvider } from "@emberly/zenith-client";
import { useState, useCallback } from "react";
import { Delete as DeleteIcon } from "@mui/icons-material";
import OrderSection from "../../../../common/sections/OrderSection";
import { CanOrderBeDeleted, GetOrderState, GetOrderStateColor } from "../../../../../common/orders";
import { OrderEnumsLists } from "../../../../../common/constants";
import { OrderProvider } from "../../../../../providers/OrderProvider";
import { useMission } from "../../../../../providers/MissionProvider";

const useStyles = makeStyles()(
  (theme) => ({
    titleContainer: {
      width: "100%",
      marginBottom: theme.spacing(2)
    },
  })
);

export default function OrderCard(props) {
  const { order, onDelete, sectionRef } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { orders, mission } = useMission();

  // menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = useCallback(() => setAnchorEl(null), []);
  const handleOpen = useCallback(ev => setAnchorEl(ev.currentTarget), []);

  const orderState = GetOrderState(order);
  const canDelete = CanOrderBeDeleted(orderState, order, orders, mission);

  return (
    <ToggleSurface
      md={12} lg={6}
      title={
        <Grid container direction="row" className={classes.titleContainer} alignItems="center" spacing={1}>
          <Grid item>
            <Typography variant="h6">{t("mission:cards:orderCard:title")} {order.name}</Typography>
          </Grid>
          <Grid item xs>
            <Typography color="textSecondary" variant="body2" textAlign="left">{t("entity:order:number")} {order.number}</Typography>
          </Grid>
          <Grid item>
            <Chip label={t(`order:enums:orderState:${OrderEnumsLists.OrderState[orderState]}`)} color={GetOrderStateColor(orderState)} />
          </Grid>
          <Grid item>
            <IconButton
              onClick={handleOpen}
            >
              <MoreIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                onClick={onDelete}
                disabled={!canDelete}
              >
                <ListItemIcon>
                  <DeleteIcon />
                </ListItemIcon>
                <ListItemText>
                  {t("mission:cards:orderCard:delete")}
                </ListItemText>
              </MenuItem>
            </Menu>

          </Grid>
        </Grid>
      }
    >
      <EntityContextProvider id={order.id} type="Order">
        <OrderProvider>
          <OrderSection sectionRef={sectionRef} />
        </OrderProvider>
      </EntityContextProvider>
    </ToggleSurface>
  );
}

