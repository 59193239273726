import { groupBy } from "@emberly/zenith-client";
import moment from "moment/moment";
import { getCurrency } from "../../providers/StationProvider";
import { GetTargetDetails } from "../mission";
import { MissionEnums, MissionEnumsLists } from "../constants";
import { FixNumberData } from "../orders";
import { country } from "../helpers";

export function convertTo(journalLines, data, t) {
  const lines = [makeHeader()];
  const invoices = groupBy(journalLines, line => line.orderId);
  const { missions } = data;

  for (let orderId of invoices.keys()) {
    const invoiceLines = invoices.get(orderId);
    const initLine = invoiceLines[0];
    const missionId = initLine.missionId;
    const mission = missions.find(t => t.id === missionId);

    const missionLines = makeMissionLines(mission, t);

    for (let i = 0; i < missionLines.length; i++) {
      lines.push(makeTextLine(missionLines[i], initLine, t));
    }

    lines.push(makeTextLine(`${t("invoiceExportLines:comment")}: ${initLine.comment || "-"}`, initLine, t));

    // TODO add intro lines based on mission etc, using makeTextLine

    for (let i = 0; i < invoiceLines.length; i++) {
      lines.push(makeJournalLine(invoiceLines[i], t));
    }
  }

  return lines.join("\n");
}


export function makeMissionLines(mission, t) {
  const lines = [];
  const targetDetails = GetTargetDetails(mission);
  const storage = mission.storageTask;
  const insuranceDetails = mission?.actors?.contract?.insurance;
  const requester = mission.actors.ownerIsNotRequester ? mission.actors.requester : mission.actors.owner;



  if (!!targetDetails) {
    lines.push(`${t("invoiceExportLines:object")}: ${targetDetails.make} ${targetDetails.model}`);
    lines.push(`${t("entity:mission:target:registration")}: ${targetDetails.registration}`)
  }


  lines.push(`${t("invoiceExportLines:missionType")}: ${t(`mission:enums:type:${MissionEnumsLists.Type[mission.details.type]}`)}`);

  if (mission.details.type === MissionEnums.Type.Salvage) {
    lines.push(`${t("invoiceExportLines:cause")}: ${mission.details.cause?.name || ""}`);
  }

  lines.push(`${t("invoiceExportLines:created")}: ${moment(mission.created).format("lll")}`)

  lines.push(`${t("invoiceExportLines:agreementReference")}: ${mission.actors?.contract?.reference || "-"}`)

  if (!!insuranceDetails?.insurer) {
    lines.push(`${t("invoiceExportLines:insuranceCompany")}: ${insuranceDetails.insurer}`);
  }

  if (!!requester) {
    lines.push(`${t("invoiceExportLines:requester")}: ${requester.name}`);
  }

  if (!!storage) {
    const stored = moment(storage.stored);
    const delivered = !!storage.deliveryTime ? moment(storage.deliveryTime) : moment();
    const days = delivered.diff(stored, "days");
    if (days > 0) {
      lines.push(`${t("invoiceExportLines:storage")}: ${days} ${days === 1 ? t("invoiceExportLines:day") : t("invoiceExportLines:days")}`);
    }
  }


  for (let i = 0; i < mission.salvageTasks.length; i++) {
    const task = mission.salvageTasks[i];
    const route = task.route;
    const waypoints = route.waypoints;
    const len = waypoints?.length || 0;

    if (len === 0) {
      continue;
    } else if (len === 1) {
      // assistance
      const location = waypoints[0];
      lines.push(`${t("invoiceExportLines:assistance")}: ${location.address}`);
    } else {
      // transport
      const destination = waypoints[len - 1];
      lines.push(`${t("invoiceExportLines:transport")}: ${destination.address}`);
    }
  }

  return lines;
}


export function makeJournalLine(journalLine, t) {

  const { item, customer, sender, reference, contactPerson } = journalLine;
  const { productGroup } = item;
  const { vatCode } = productGroup;

  const orderDate = moment(journalLine.orderDate);

  let line = [];

  // ImpSystem
  line.push("Assist");

  // ImpSystemRef
  line.push("");

  // InvoiceGroup
  line.push("");

  // OrderNo
  line.push(journalLine.orderNumber)

  // LineNo
  line.push(1);

  // Date
  line.push(orderDate.format("YYYYMMDD"));

  // CustomerNo
  line.push(customer.customerNumber || "");

  // InvoiceTemplate
  line.push("");

  // ReadyToInvoice
  line.push("1");

  // Project
  line.push("");

  // Object
  line.push("");

  // ObjectValue
  line.push("");

  // Posting 1
  line.push("");

  // Posting 2
  line.push("");

  // XGL
  line.push("");

  // Currency 
  line.push(getCurrency(t, journalLine.item.price.currency)); // TODO

  // Product 
  line.push(productGroup.code)

  // XLG
  line.push("");

  // Text
  line.push(Limit(item.name, 255));

  // Pricelist
  line.push("");

  // PriceGroup
  line.push("");

  // Unit
  line.push(item.unit || t("invoiceExportLines:defaultUnit"));

  // Quantity
  line.push(FixNumberData(item.quantity));

  // Cost Price
  line.push("");

  // UnitPriceImp
  line.push(item.price.value);

  // Discount%
  line.push(item.discount);

  // DiscountAmount
  line.push("");

  // TaxRule
  line.push(vatCode.code);

  // PrePaidToBank
  line.push("");

  // PrePaidDate
  line.push("");

  // PaymentRef
  line.push(Limit(reference, 50));

  // Xidentifier
  line.push("");

  // ExternalOrder
  line.push(journalLine.orderNumber);

  // SubledgerName
  line.push(customer.name);

  // CompanyNO
  line.push(customer.organizationNumber);

  //SubledgerGroup
  line.push("");

  // BankAccount
  line.push("");

  // PaymentTerms
  line.push("");

  // UpdateAddress
  line.push(0);

  // StreetAddressLine1
  line.push(Limit(customer.billing.address, 255));

  // StreetAddressLine2
  line.push(Limit(customer.billing.address2 || "", 255));

  // ZipCode
  line.push(customer.billing.postNumber);

  // City
  line.push(customer.billing.area);

  // State
  line.push("");

  // Country
  line.push(Limit(country(t, customer.billing.country), 255));

  // InvoiceStreetAddressLine1
  line.push(Limit(customer.billing.address, 255));

  // InvoiceStreetAddressLine2
  line.push(Limit(customer.billing.address2 || "", 255));

  // InvoiceZipCode2
  line.push(customer.billing.postNumber);

  // InvoiceCity2
  line.push(customer.billing.area);

  // InvoiceState2
  line.push("");

  // InvoiceCountry2
  line.push(customer.billing.area);

  // Phone
  line.push(customer.phoneNumber || ""); // ?

  // Email
  line.push(customer.email || "");

  // YourRef
  line.push(contactPerson?.name || "");

  // Dummy1-5
  line.push("");
  line.push("");
  line.push("");
  line.push("");
  line.push("");

  // EOL
  line.push("x");

  return line.join(";");
}


export function makeTextLine(text, journalLine, t) {

  const { item, customer, sender, reference, contactPerson } = journalLine;
  const { productGroup } = item;
  const { vatCode } = productGroup;

  const orderDate = moment(journalLine.orderDate);

  let line = [];

  // ImpSystem
  line.push("Assist");

  // ImpSystemRef
  line.push("");

  // InvoiceGroup
  line.push("");

  // OrderNo
  line.push(journalLine.orderNumber)

  // LineNo
  line.push(1);

  // Date
  line.push(orderDate.format("YYYYMMDD"));

  // CustomerNo
  line.push(customer.customerNumber || "");

  // InvoiceTemplate
  line.push("");

  // ReadyToInvoice
  line.push("1");

  // Project
  line.push("");

  // Object
  line.push("");

  // ObjectValue
  line.push("");

  // Posting 1
  line.push("");

  // Posting 2
  line.push("");

  // XGL
  line.push("");

  // Currency 
  line.push(""); // TODO

  // Product 
  line.push("T")

  // XLG
  line.push("");

  // Text
  line.push(Limit(text, 255));

  // Pricelist
  line.push("");

  // PriceGroup
  line.push("");

  // Unit
  line.push("");

  // Quantity
  line.push("");

  // Cost Price
  line.push("");

  // UnitPriceImp
  line.push("");

  // Discount%
  line.push("");

  // DiscountAmount
  line.push("");

  // TaxRule
  line.push("");

  // PrePaidToBank
  line.push("");

  // PrePaidDate
  line.push("");

  // PaymentRef
  line.push(Limit(reference, 50));

  // Xidentifier
  line.push("");

  // ExternalOrder
  line.push(journalLine.orderNumber);

  // SubledgerName
  line.push(customer.name);

  // CompanyNO
  line.push(customer.organizationNumber);

  //SubledgerGroup
  line.push("");

  // BankAccount
  line.push("");

  // PaymentTerms
  line.push("");

  // UpdateAddress
  line.push(0);

  // StreetAddressLine1
  line.push(Limit(customer.billing.address, 255));

  // StreetAddressLine2
  line.push(Limit(customer.billing.address2 || "", 255));

  // ZipCode
  line.push(customer.billing.postNumber);

  // City
  line.push(customer.billing.area);

  // State
  line.push("");

  // Country
  line.push(Limit(country(t, customer.billing.country), 255));

  // InvoiceStreetAddressLine1
  line.push(Limit(customer.billing.address, 255));

  // InvoiceStreetAddressLine2
  line.push(Limit(customer.billing.address2 || "", 255));

  // InvoiceZipCode2
  line.push(customer.billing.postNumber);

  // InvoiceCity2
  line.push(customer.billing.area);

  // InvoiceState2
  line.push("");

  // InvoiceCountry2
  line.push(customer.billing.area);

  // Phone
  line.push(customer.phoneNumber || ""); // ?

  // Email
  line.push(customer.email || "");

  // YourRef
  line.push(contactPerson?.name || "");

  // Dummy1-5
  line.push("");
  line.push("");
  line.push("");
  line.push("");
  line.push("");

  // EOL
  line.push("x");

  return line.join(";");
}
function makeHeader() {
  const line = [
    "ImpSystem",
    "ImpSystemRef",
    "InvoiceGroup",
    "OrderNo",
    "LineNo",
    "Date",
    "CustomerNo",
    "InvoiceTemplate",
    "ReadyToInvoice",
    "Project",
    "Object",
    "ObjectValue",
    "Posting1",
    "Posting2",
    "XGL",
    "Currency",
    "Product",
    "XLG",
    "Text",
    "Pricelist",
    "PriceGroup",
    "Unit",
    "Quantity",
    "Cost Price",
    "UnitPriceImp",
    "Discount%",
    "DiscountAmount",
    "TaxRule",
    "PrePaidToBank",
    "PrePaidDate",
    "PaymentRef",
    "Xidentifier",
    "ExternalOrder",
    "SubledgerName",
    "CompanyNo",
    "SubledgerGroup",
    "BankAccount",
    "PaymentTerms",
    "UpdateAddress",
    "StreetAddressLine1",
    "StreetAddressLine2",
    "ZipCode",
    "City",
    "State",
    "Country",
    "InvoiceStreetAddress2Line1",
    "InvoiceStreetAddress2Line2",
    "InvoiceZipCode2",
    "InvoiceCity2",
    "InvoiceState2",
    "InvoiceCountry2",
    "Phone",
    "Email",
    "YourRef",
    "Dummy1",
    "Dummy2",
    "Dummy3",
    "Dummy4",
    "Dummy5",
    "EOL",
  ];

  return line.join(";");
}

function Limit(text, limit = 25) {
  if (!text) return "";
  if (text.length <= limit) return text;
  return text.substring(0, limit - 1);
}