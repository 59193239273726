import { makeStyles } from "tss-react/mui";
import { Grid, Typography, Avatar, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ActivityEnumsLists } from "../../../../common/constants";
import { NavLink } from "react-router-dom";
import { GetResourceLink } from "../../../../common/activity";
import { CompactTimeView } from "../../../common/core/Time";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      padding: theme.spacing(0),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      textDecoration: "none",
      color: "inherit",
    },
    avatar: {
      marginTop: theme.spacing(0.5)
    },
  })
);

export default function ActivityLogItem(props) {
  const { item } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();

  const typeName = ActivityEnumsLists.Type[item.activityType];
  const categoryName = ActivityEnumsLists.Category[item.activityCategory];

  return (
    <NavLink to={GetResourceLink(item)} className={classes.root}>
      <Grid container direction="row" spacing={2} alignItems="flex-start">

        <Grid item>
          <Avatar
            className={classes.avatar}
            alt={item.sender?.name}
            src={item.avatar}
          />
        </Grid>

        <Grid item container xs alignItems="baseline" spacing={1}>
          <Grid item xs>
            <Typography variant="subtitle2">
              {t(`activity:enums:type:${typeName}`)}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {t(`activity:enums:category:${categoryName}`)} {!!item?.resourceNumber ? `#${item.resourceNumber}` : ""}
            </Typography>
            <Typography variant="caption" color="textSecondary">{item.sender?.name || ""}</Typography>
          </Grid>

          <Grid item>
            <Typography variant="caption" color="textSecondary">
              <CompactTimeView time={item.created} />
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

      </Grid>
    </NavLink>
  );
}


